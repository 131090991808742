import React, { Component } from 'react';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
export class EnvioLote extends Component{
  constructor(){
    super();
    this.state={eventos:[], isPronto:false};
  }
  displaySelection() {
    let data= this.props.eventos;
    console.log(data);
    if(!data || data.length === 0) {
            return <div style={{textAlign: 'left'}}><em> Selecione ao menos 1 evento.</em></div>;
        }else {
            if(data instanceof Array){
                return (
                    <table  className="pure-table pure-table-horizontal" style={{width:'100%'}}>
                        <thead>
                            <tr>
                                <th style={{textAlign:'center'}}>#</th>
                                <th style={{textAlign:'center'}}>Id</th>
                                <th style={{textAlign:'center'}}>Evento</th>
                                <th style={{textAlign:'center'}}>Descrição</th>
                            </tr>
                        </thead>
                        <tbody>
                    {data.map(
                        (evento,i) => (                            
                        <tr key={i}> 
                            <td><img src={window.location.origin+'/EsocialReact/img/icons/Success.svg'} alt="" width={16} height={16} /></td>
                            <td>{evento.idEventoFila}</td>
                            <td><label style={{marginLeft:'12px'}}>{evento.codEventoEsocial + ' - ' + evento.eventoEsocial} </label></td>
                            <td> <strong>{evento.objetoAlvoEvento}</strong></td>
                        </tr>)
                        )
                    }
                    </tbody>
                    </table>
                );          
            }
        }
  }
  render() {
    return(
      <div style={{textAlign:'center'}}>
        {this.displaySelection()}
      </div>
    );
  }
}
