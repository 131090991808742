import React, { Component } from 'react';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import {BreadCrumb} from 'primereact/breadcrumb';
import {Messages} from 'primereact/messages';
import{ MenuBar } from '../../componentes/MenuBar';
import {Panel} from 'primereact/panel';
import {Menu} from 'primereact/menu';
import{TblImportacao} from '../Relatorios/Tabela/TblImportacao.js'
import {ProgressBar} from 'primereact/progressbar';
import $ from 'jquery';
import { FiltroEventosEnviados } from './Filtro/FiltroEventosEnviados';
import usuarioService from '../../services/usuarioService.js';

export class RelImportacao extends Component{
  constructor(){
    super();
    this.state={
      home :{icon: 'pi pi-home'},
      agendamentos:[],
      tipoEvento:"1",
      //Refazer com Controler preenchendo essas informações
      eventos:[],
      //Refazer com Controler preenchendo essas informações
      grupos:[
        {label:'Eventos de Tabela', value:6},
        {label:'Eventos Não Periódicos', value:7},
        {label:'Eventos Periódicos', value:8}
      ],
      menu:[],
      usuario:{},
      filtro:false,
      filtroPesquisa:{},
      pgrbar:'none',
      grupo:6,
      evento:'',
      resultado:[]
    };
    this.handleEvento = this.handleEvento.bind(this);
    this.handleGrupo = this.handleGrupo.bind(this);
  }
  brandTemplate(rowData, column) {
    var src = "/img/icons/Error.svg";
    return <img src={src} alt={rowData.brand} width="32px" height="32px" />;
}
construirMenu(){
  var baseurl = window.location.protocol + "//" + window.location.host ;
  console.log(this.state.usuario.login);
  $.ajax({      
    url:"/EsocialAPI/usuario/menu/relatorios/"+this.state.usuario.login,
    dataType: 'json',
    error:function(){
      window.location.href= baseurl;
    }, 
    success:function(retorno){
      this.setState({menu : retorno});
    }.bind(this),
    beforeSend: function(xhr, settings) { xhr.setRequestHeader('Authorization','Bearer ' + localStorage.getItem('auth-token')); }
  });
}
componentWillMount(){
  $.ajax({
    url:"/EsocialAPI/usuario/logado/",
    dataType: 'json',
    success:function(resposta){
      this.setState({usuario:resposta});
      this.construirMenu();
    }.bind(this),
    beforeSend: function(xhr, settings) { xhr.setRequestHeader('Authorization','Bearer ' + localStorage.getItem('auth-token')); }
  });  
  this.atualizaEventos();
}
alteraProgress = (valor) =>{
  this.setState({pgrbar:valor})
}

handleEvento(evento){
  console.log(evento);
  var eventoTemp = Object.assign({}, this.state.evento);
  eventoTemp = evento;
  this.setState({evento: eventoTemp},  () => {console.log('The state has been updated.')});
  console.log(eventoTemp);
  console.log(this.state.evento);
}

handleGrupo(grupo){

  var grupoTemp = Object.assign({}, this.state.grupo);
  grupoTemp = grupo;
  this.setState({grupo: grupoTemp}, () => {console.log('The state has been updated.')});
  console.log(grupoTemp);
  //console.log(this.state.grupo);
  this.atualizaEventos(grupoTemp);
}

handleClick(){
  console.log(JSON.stringify(this.montaFiltroEvtEnviados()));
  $.ajax({
    url:'/EsocialAPI/relatorios/eventosimportados/',
    type: 'post',
    dataType: 'json',
    data: JSON.stringify(this.montaFiltroEvtEnviados()),
    success: function(resposta) {
      console.log(resposta);
      //console.log(resposta.datalhe.nome);
      this.setState({resultado:resposta});
    }.bind(this),
    error:function(erro){
      console.log(erro);
    },
    contentType:'application/json',
    beforeSend: function(xhr, settings) { xhr.setRequestHeader('Authorization','Bearer ' + localStorage.getItem('auth-token')); }
  });
}
atualizaEventos(idGrupo){
  if(idGrupo==null){
    idGrupo=this.state.grupo;
  }
  var urlEventos = '/EsocialAPI/evento/combo/'+idGrupo;
  console.log(urlEventos);
  $.ajax({
    url:urlEventos,
    type: 'get',
    dataType: 'json',
    success: function(resposta) {
      console.log(resposta);
      this.setState({eventos:resposta});
    }.bind(this),
    error:function(erro){
      console.log(erro);
    },
    contentType:'application/json',
    beforeSend: function(xhr, settings) { xhr.setRequestHeader('Authorization','Bearer ' + localStorage.getItem('auth-token')); }
  });
}
montaFiltroEvtEnviados(){
  var filtroEvtEnviados = {
    idGrupo: this.state.grupo,
    codEvento: this.state.evento
  }
  return filtroEvtEnviados;
}
componentDidMount(){
  this.consultaUsuario();
}
consultaUsuario(){
  usuarioService.consultaUsuario((usuario) => {
    this.setState({ usuario });
  });
}

render() {
  const items = [
      {label:'Home',icon:'pi pi-home', url: '/EsocialReact/home'},
      {label:'Relatórios',icon:'pi pi-home', url: '/EsocialReact/relatorio/evenviados'},
      {label:'Relatório de Importação de eventos',icon:'pi-home', url: '/EsocialReact/relatorio/importacao'}
    ];


  return(
    <div>
      <MenuBar usuario={this.state.usuario} />
      <div className="content">
        <div className="content align-center">
            <Messages ref={(el) => this.messages = el} />
        </div>
        <div className="pure-u-5-5" style={{marginTop:'15px'}}>
        <Panel header="Importação de Eventos">
            <BreadCrumb  home="principal" model={items}/>
            <em>Relatórios de Sistema</em>
            <br />
            <div className="pure-u-1-5" style={{marginTop:'15px'}}>
              <Menu model={this.state.menu}/>
            </div>
            <div className="pure-u-4-5 content-section implementation" style={{marginTop:'15px'}}>
            <Panel header="Filtro">
              <br />
                
                <FiltroEventosEnviados 
                filtroPesquisa={this.state.filtroPesquisa} 
                pgrbar={this.alteraProgress}
                grupos={this.state.grupos}
                eventos={this.state.eventos}
                grupo={this.state.grupo}
                evento={this.state.evento}
                onChangeEvento={(evento)=>this.handleEvento(evento)}
                onChangeGrupo={(grupo)=>this.handleGrupo(grupo)}
                onClick={()=>this.handleClick()}
                />
            </Panel>
            <br />
              <ProgressBar mode="indeterminate" style={{height: '6px', display: this.state.pgrbar }} />
            <br />
              <TblImportacao eventos={this.state.resultado}/>
          </div>
        </Panel>
        </div>
        
      </div>
      
      <div className="footer l-box is-center">
        {/* <PermissionCheck usuario={this.state.usuario}  /> */}
      </div>
    </div>
  );
  }
}