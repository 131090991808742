import React, { Component } from 'react';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import {Messages} from 'primereact/messages';
import{ MenuBar } from '../componentes/MenuBar';
import {BreadCrumb} from 'primereact/breadcrumb';
import {Panel} from 'primereact/panel';
import {TabView,TabPanel} from 'primereact/tabview';
import {Menu} from 'primereact/menu';
import{ TblImportacao } from '../sessoes/Importacao/TblImportacao';

export class Importacao extends Component{
  constructor(){
    super();
    this.state={home :{icon: 'pi pi-home'},expandedRows:null,agendamentos:[],tipoEvento:"1",grupo:1,activeIndex:0,items: [
      {
          label: 'Eventos',
          items: [{label: 'Tabela', icon: 'pi pi-fw pi-upload', command:()=>{ this.setState({activeIndex:0})}},
                  {label: 'Não Periódicos', icon: 'pi pi-fw pi-calendar', command:()=>{ this.setState({activeIndex:1})}},
                  {label: 'Periódicos', icon: 'pi pi-fw pi-calendar', command:()=>{ this.setState({activeIndex:2})}}
                 ]
      }
  ],layout: 'list',eventos:[],frmEvento:{}};
  }



  render() {
    const items = [
      {label:'Home',icon:'pi pi-home', url: '/EsocialReact/home'},
      {label:'Administração',icon:'pi pi-home', url: '/EsocialReact/home'},
      {label:'Importação',icon:'pi pi-home', url: '/EsocialReact/importacao'}
    ];
    return(
      <div style={{marginTop:'30px'}}>
        <MenuBar/>
        <div className="content">
          <div className="content align-center">
              <Messages ref={(el) => this.messages = el}></Messages>
              <div className="pure-u-5-5">
              <Panel header="Importação de Eventos">
                  <BreadCrumb  home="principal" model={items}/>
                  <div className="pure-g">
                    <div className="pure-u-1-5">
                        <Menu model={this.state.items}/>
                    </div>


                    <div className="pure-u-4-5">
                      <TabView activeIndex={this.state.activeIndex} onTabChange={(e) => this.setState({activeIndex: e.index})}>
                          <TabPanel header="Eventos de Tabela">
                              <h5>Lista de eventos</h5>
                              <TblImportacao idGrupo="1" />
                          </TabPanel>
                          <TabPanel header="Eventos não Periódicos">
                              <TblImportacao idGrupo="2" />
                          </TabPanel>
                          <TabPanel header="Eventos Periódicos">
                              <TblImportacao idGrupo="3" />
                          </TabPanel>
                      </TabView>
                    </div>
                  </div>
              </Panel>
              </div>
          </div>
            </div>
            <div className="footer l-box is-center">
                Copyright © Todos os direitos reservados.
            </div>

      </div>
    );
  }
}
