import React, { Component } from 'react';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import {BreadCrumb} from 'primereact/breadcrumb';
import {Messages} from 'primereact/messages';
import{ MenuBar } from '../../componentes/MenuBar';
import {Panel} from 'primereact/panel';
import {Menu} from 'primereact/menu';
import{FiltroEventoTrabalhador} from '../Relatorios/Filtro/FiltroEventoTrabalhador.js'
import{TblTrabalhador} from '../Relatorios/Tabela/TblTrabalhador.js'
import {ProgressBar} from 'primereact/progressbar';
import $ from 'jquery';
import usuarioService from '../../services/usuarioService.js';

export class RelEventosTrabalhador extends Component{
  constructor(){
    super();
    this.state={home :{icon: 'pi pi-home'},
                agendamentos:[],tipoEvento:"1",
                eventos:[],
                menu:[],
                usuario:{},
                filtro:false,
                filtroPesquisa:{},
                pgrbar:'none', 
                cpf:'', 
                matricula:'' ,
                nome:''
              };
    this.handleButtonClick = this.handleButtonClick.bind(this);
  }
  brandTemplate(rowData, column) {
    var src = "/EsocialReact/img/icons/Error.svg";
    return <img src={src} alt={rowData.brand} width="32px" height="32px" />;
}

handleCpf(cpf){
  var cpfTemp = Object.assign({}, this.state.cpf);
  cpfTemp = cpf;
  this.setState({cpf: cpfTemp});
}

handleMatricula(matricula){
  var matriculaTemp = Object.assign({}, this.state.matricula);
  matriculaTemp = matricula;
  this.setState({matricula: matriculaTemp});
}

handleNome(nome){
  var nomeTemp = Object.assign({}, this.state.nome);
  nomeTemp = nome;
  this.setState({nome: nomeTemp});
}

handleButtonClick(){
  console.log(JSON.stringify(this.montaFiltroEvtTrabalhador()));
  $.ajax({
    url:'/EsocialAPI/relatorios/eventostrabalhador/',
    type: 'post',
    dataType: 'json',
    data: JSON.stringify(this.montaFiltroEvtTrabalhador()),
    success: function(resposta) {
      console.log(resposta);
      this.setState({eventos: resposta});
    }.bind(this),
    error:function(erro){
      console.log(erro);
    },
    contentType:'application/json',
    beforeSend: function(xhr, settings) { xhr.setRequestHeader('Authorization','Bearer ' + localStorage.getItem('auth-token')); }
  });
}

montaFiltroEvtTrabalhador(){
  var filtroEvtTrabalhador ={
    cpf: this.state.cpf,
    matricula: this.state.matricula,
    nome: this.state.nome
  }
  return filtroEvtTrabalhador;
}

construirMenu(){
  var baseurl = window.location.protocol + "//" + window.location.host + "/EsocialReact";
  console.log(this.state.usuario.login);
  $.ajax({      
    url:"/EsocialAPI/usuario/menu/relatorios/"+this.state.usuario.login,
    dataType: 'json',
    error:function(){
      window.location.href= baseurl;
    }, 
    success:function(retorno){
      this.setState({menu : retorno});
    }.bind(this),
    beforeSend: function(xhr, settings) { xhr.setRequestHeader('Authorization','Bearer ' + localStorage.getItem('auth-token')); }
  });
}
componentWillMount(){
  $.ajax({
    url:"/EsocialAPI/usuario/logado/",
    dataType: 'json',
    success:function(resposta){
      this.setState({usuario:resposta});
      this.construirMenu();
    }.bind(this),
    beforeSend: function(xhr, settings) { xhr.setRequestHeader('Authorization','Bearer ' + localStorage.getItem('auth-token')); }
  });  
  
}
alteraProgress = (valor) =>{
  this.setState({pgrbar:valor})
}
componentDidMount(){
  this.consultaUsuario();
}
consultaUsuario(){
  usuarioService.consultaUsuario((usuario) => {
    this.setState({ usuario });
  });
}
  render() {
    const items = [
        {label:'Home',icon:'pi pi-home', url: '/EsocialReact/home'},
        {label:'Relatórios',icon:'pi pi-home', url: '/EsocialReact/relatorio/evTrab'},
        {label:'Relatório de Eventos do Trabalhador',icon:'pi-home', url: '/EsocialReact/relatorio/evTrab'}
      ];
    return(
      <div>
        <MenuBar usuario={this.state.usuario} />
        <div className="content">
          <div className="content align-center">
              <Messages ref={(el) => this.messages = el} />
          </div>
          <div className="pure-u-5-5" style={{marginTop:'15px'}}>
          <Panel header="Afastamentos">
              <BreadCrumb  home="principal" model={items}/>
              <em>Relatórios de Sistema</em>
              <br />
              <div className="pure-u-1-5" style={{marginTop:'15px'}}>
                <Menu model={this.state.menu}/>
              </div>
              <div className="pure-u-4-5 content-section implementation" style={{marginTop:'15px'}}>
              <Panel header="Filtro">
                <br />
                  <FiltroEventoTrabalhador 
                    filtroPesquisa={this.state.filtroPesquisa} 
                    pgrbar={this.alteraProgress} 
                    cpf={this.state.cpf}
                    matricula={this.state.matricula}
                    nome={this.state.nome}
                    onChangeCpf={(cpf)=>this.handleCpf(cpf)} 
                    onChangeMatricula={(matricula)=>this.handleMatricula(matricula)}
                    onChangeNome={(nome)=>this.handleNome(nome)}
                    onClick={()=>this.handleButtonClick()}
                    />
              </Panel>
              <br />
                <ProgressBar mode="indeterminate" style={{height: '6px', display: this.state.pgrbar }} />
              <br />
                <TblTrabalhador eventos={this.state.eventos} />
            </div>
          </Panel>
          </div>
          
        </div>

        
        <div className="footer l-box is-center">
        {/* <PermissionCheck usuario={this.state.usuario}  /> */}
        </div>

      </div>
    );
  }
}