import React, { Component } from 'react';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';
import { Messages } from 'primereact/messages';
import { MenuBar } from '../componentes/MenuBar';
import { Panel } from 'primereact/panel';
import { XmlEditor } from '../sessoes/Processamento/preview/XmlEditor';
import { HistoricoEvento } from '../sessoes/Processamento/preview/HistoricoEvento';
import { TabView, TabPanel } from 'primereact/tabview';
import { EventosVinculados } from '../sessoes/EventoFila/EventosVinculados';
import $ from 'jquery';
import { BreadCrumb } from 'primereact/breadcrumb';
import EnvioComponent from './Processamento/preview/EnvioComponent';
import { Dialog } from 'primereact/dialog';
import { Card } from 'primereact/card';
import { Divider } from 'primereact/divider';
import { Badge } from 'primereact/badge';
import { Calendar } from 'primereact/calendar';
import { Tooltip } from 'primereact/tooltip';
import Utils from '../utils/Util';
import { Button } from 'primereact/button';
import '../css/css/EventoFila.css'
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import { Toast } from 'primereact/toast';
import { locale, addLocale, updateLocaleOption, updateLocaleOptions, localeOption, localeOptions } from 'primereact/api';
import pt from '../pt.json';
import usuarioService from '../services/usuarioService';

export class EventoFila extends Component {
  constructor() {
    super();
    this.state = {
      home: { icon: 'pi pi-home' }, agendamentos: [], tipoEvento: "1",
      evento: {
        catalogo: "",
        codEvento: 0,
        dataEntradaFila: 0,
        evento: "",
        grupoDescricao: "",
        grupoTipo: 0,
        id: 0,
        idEvento: 0,
        natureza: "",
        objDetalhe: null,
        idObj: "",
        status: ""
      }
      , chaveEvento: "Matrícula"
      , desabilitaSave: true
      , txtCatalogo: ""
      , txtGrupo: ""
      , txtEvento: ""
      , txtNatureza: ""
      , txtStatus: ""
      , txtMatricula: ""
      , txtNome: ""
      , usuario: {}
      , totalizadoresDisponiveis: true
      , isModalEnvioVisible: false
      , respostaEnvio: []
      , isEventosEnviados: false
      , tipoProcessamento: 0
      , dataAlteracao: null
      , eventosAlteracao: [2205, 2206, 2306]
      , statusAlteracaoPermitida: ['PENDENTE', 'REJEITADO']
    };
    this.classificaGrupoEvento = this.classificaGrupoEvento.bind(this);
    this.onDataObjetoChange = this.onDataObjetoChange.bind(this);
    this.renderDataAlteracao = this.renderDataAlteracao.bind(this);
    this.accept = this.accept.bind(this);
    this.reject = this.reject.bind(this);
    this.confirm = this.confirm.bind(this);
    this.changeLocale = this.changeLocale.bind(this);
  }

  changeLocale() {
    addLocale('pt', pt.pt);
    locale('pt');
  }


  accept() {
    const dataFormatada = Utils.timeStampToDate(this.state.dataAlteracao);
    console.log(dataFormatada);
    console.log(this.state.evento.id);
    const dados = {
      dataObjeto: dataFormatada
    }

    fetch("/EsocialAPI/eventoFila/dataobjeto/" + this.state.evento.id, {
      method: "POST",
      headers: {
        "Content-Type": "application/json; charset=UTF-8",
        "Authorization": "Bearer " + localStorage.getItem("auth-token")
      },
      body: JSON.stringify(dados)
    })
      .then(response => {
        if (!response.ok) {
          this.toast.show({ severity: 'error', summary: 'Erro', detail: 'Erro ao salvar. Consulte o suporte.', life: 3000 });
          throw new Error("Erro na requisição.");
        }
        return response.json();
      })
      .then(resposta => {
        this.toast.show({ severity: 'info', summary: 'Sucesso', detail: 'Evento atualizado.', life: 3000 });
      })
      .catch(error => {
        Utils.handleAjaxError(error);
        this.toast.show({ severity: 'error', summary: 'Erro', detail: 'Erro ao salvar. Consulte o suporte.', life: 3000 });
      });

  }

  reject() {
  }

  confirm() {
    confirmDialog({
      message: () => {
        return (
          <div>
            <p>Você confirma essa alteração de data?</p>
            <p>Para mais informações, consulte o campo dtAlteracao dos eventos S-2205/S-2206/S-2306 no leaiute do eSocial.</p>
          </div>
        )
      },
      header: 'Confirmação de Alteração de Data',
      icon: 'pi pi-exclamation-triangle',
      accept: this.accept,
      reject: this.reject
    });
  }
  componentDidMount() {
    this.consultaUsuario();
    this.atualizaEventos();
  }
  enviarEvento() {
    //this.setState({activeIndex:1,tabEnviandoDisable: false});
    console.log(this.props.match.id);
    $.ajax({
      type: "POST",
      data: { 'idEventoFila[]': this.props.match.params.id },
      url: "/EsocialAPI/api/processamento/enviar/",
      dataType: 'json',
      success: function (resposta) {
        console.log(resposta);
        console.log('Retorno da resposta do envio');
        $('#pgrEnviar').css("display", "none");
        this.setState({ selecionados: [] });
        this.setState({ respostaEnvio: resposta });
        this.setState({ isEventosEnviados: true });

      }.bind(this),
      beforeSend: function (xhr, settings) { xhr.setRequestHeader('Authorization', 'Bearer ' + localStorage.getItem('auth-token')); }
    });
  }

  handleBotaoEnvioClick() {
    this.setState({ isModalEnvioVisible: true });
    this.enviarEvento();
  }
  consultaUsuario() {
    usuarioService.consultaUsuario((usuario) => {
      this.setState({ usuario });
    });
  }
  atualizaEventos() {
    var url = "/EsocialAPI/eventoFila/" + this.props.match.params.id;
    $.ajax({
      url: "/EsocialAPI/eventoFila/" + this.props.match.params.id,
      dataType: 'json',
      success: function (resposta) {
        this.classificaGrupoEvento(resposta.grupoDescricao);
        if (resposta.grupoTipo === 1) {
          this.setState({ chaveEvento: "id" });
        }
        let objDetalhe = JSON.parse(resposta.objDetalhe);
        let dataObjeto = Utils.nvl(resposta.dataObjeto, resposta.dataEntradaFilaFormatada)

        let dataObjetoFormatado = null;
        if (dataObjeto != null && dataObjeto != undefined) {
          dataObjetoFormatado = Utils.stringToDate(dataObjeto);
        }
        console.log(resposta)
        try {
          if (objDetalhe !== null) {
            if (objDetalhe.codigo !== null && objDetalhe.codigo !== undefined) {
              this.setState({ evento: resposta, txtMatricula: objDetalhe.codigo, txtNome: objDetalhe.descricao, dataAlteracao: dataObjetoFormatado });
            }
            if (objDetalhe.matricula !== null && objDetalhe.matricula !== undefined) {
              this.setState({ evento: resposta, txtMatricula: objDetalhe.matricula, txtNome: objDetalhe.nome, dataAlteracao: dataObjetoFormatado });
            }
          } else {
            this.setState({ evento: resposta, txtMatricula: 'Sem Matricula', txtNome: 'Sem Nome', dataAlteracao: dataObjetoFormatado });
          }
        } catch (erro) {
          console.log(erro);
        }
      }.bind(this),
      beforeSend: function (xhr, settings) { xhr.setRequestHeader('Authorization', 'Bearer ' + localStorage.getItem('auth-token')); }
    });
  }
  classificaGrupoEvento(grupo) {
    try {
      if (grupo === 'TABELA') {
        this.setState({ tipoProcessamento: 0 });
      }
      if (grupo === 'NÃO_PERIÓDICO') {
        this.setState({ tipoProcessamento: 1 });
      }
      if (grupo === 'PERIÓDICO') {
        this.setState({ tipoProcessamento: 2 });
      }
    } catch (error) {
      console.log(error);
    }
  }
  showDialogEnvio(resposta, show) {
    if (show) {
      console.log(this.state.respostaEnvio)
      return (
        <Dialog
          header="Informações do Envio"
          visible={this.state.isModalEnvioVisible}
          modal={true}
          onHide={() => this.handleHideDialog()}
          maximizable
        >
          <EnvioComponent isEventosEnviados={this.state.isEventosEnviados} respostaEnvio={resposta[0]}></EnvioComponent>
        </Dialog>
      )
    }
  }

  handleHideDialog() {
    this.setState({ isEventosEnviados: false });
    this.setState({ isModalEnvioVisible: false })
  }

  habilitaBotaoEnvio(evento) {
    if (evento.isEventoPronto && evento.status == 'PENDENTE') {
      return false;
    }
    return true;
  }

  onDataObjetoChange(value) {


    this.setState({ dataAlteracao: value })
  }

  renderDataAlteracao(codigoEvento, status) {
    if (this.state.eventosAlteracao.includes(codigoEvento) && this.state.statusAlteracaoPermitida.includes(status)) {
      return (
        <div>
          <div>
            <Tooltip target=".dtAlteracaoCalendar" />
            <h6 style={{ display: 'inline-block', paddingRight: '4px' }}>Data Alteração </h6>
            <i className="dtAlteracaoCalendar pi pi-info-circle" data-pr-tooltip='Data de alteração ("dtAlteracao" nos eventos S-2205/S-2206/S-2306).' />
          </div>
          <div style={{ position: 'inline-block' }}>
            <Calendar id="calendar" dateFormat="dd/mm/yy" style={{ textAlign: 'center', marginRight: '5px', marginLeft: '5px' }} value={this.state.dataAlteracao} onChange={(e) => this.onDataObjetoChange(e.value)} mask="99/99/9999" />
            <Button icon="pi pi-refresh" onClick={this.confirm} className="p-button-rounded p-button-success p-button-outlined" />
          </div>
        </div>
      )
    }
  }
  render() {
    this.changeLocale();

    let aba = this.state.evento.grupoTipo - 1;
    const items = [
      { label: 'Home', icon: 'pi pi-home', url: '/home' },
      { label: 'Processamento Fila', icon: 'pi pi-home', url: '/processamento/fila/' + this.state.tipoProcessamento + '/' },
      { label: this.state.evento.grupoDescricao, icon: 'pi pi-home', url: '/processamento/fila/' + aba + '/' }
    ];

    let severity = 'info';
    if (this.state.evento.status === 'RECEBIDO') {
      severity = 'success';
    } else if (this.state.evento.status === 'REJEITADO') {
      severity = 'danger';
    } else if (this.state.evento.status === 'PENDENTE') {
      severity = 'warning';
    }
    return (
      <div>
        {this.props.isMenuDisabled ? '' : <MenuBar usuario={this.state.usuario} />}
        <div className="content">
          <Toast ref={(el) => this.toast = el} />
          <div >
            <Panel header={<span>Status: <Badge value={this.state.evento.status} severity={severity} /></span>}>
              {this.showDialogEnvio(this.state.respostaEnvio, this.state.isModalEnvioVisible)}
              <BreadCrumb home="principal" model={items} />
              <Messages ref={(el) => this.messages = el}></Messages>
              <div className="pure-u-3-3">
              </div>
              <Card>
                <div className="p-d-flex p-jc-between">
                  <div className="p-mr-3">
                    <h6>ID</h6>
                    <span>{this.state.evento.id}</span>
                    <Divider />
                    <h6>Catalogo</h6>
                    <span>{this.state.evento.catalogo}</span>
                    <Divider />
                    {this.renderDataAlteracao(this.state.evento.codEvento, this.state.evento.status)}
                  </div>
                  <div className="p-mr-3">
                    <h6>Evento</h6>
                    <span>{`${this.state.evento.codEvento} - ${this.state.evento.evento}`}</span>
                    <Divider />
                    <h6>Grupo</h6>
                    <span>{this.state.evento.grupoDescricao}</span>
                  </div>
                  <div>
                    <h6>Natureza</h6>
                    <span>{this.state.evento.natureza}</span>
                    <Divider />
                    <h6>Detalhe</h6>
                    <span>{`${this.state.txtMatricula} - ${this.state.txtNome}`}</span>
                  </div>
                </div>
              </Card>
              <hr />
              <div >
                <TabView activeIndex={this.state.activeIndex} onTabChange={(e) => this.setState({ activeIndex: e.index })}>
                  <TabPanel header="Historico" contentStyle={{ padding: 0, margin: 0 }}>
                    <HistoricoEvento idEventoFila={this.props.match.params.id} changed={this.state.isModalEnvioVisible} />
                  </TabPanel>
                  <TabPanel header="Eventos de Alteração/Exclusão">
                    <EventosVinculados idEventoFila={this.props.match.params.id} />
                  </TabPanel>
                  <TabPanel header="XML">
                    <XmlEditor idEventoFila={this.props.match.params.id} />
                  </TabPanel>
                  <TabPanel header="Totalizadores" disabled={this.state.totalizadoresDisponiveis}>

                  </TabPanel>
                </TabView>
              </div>
            </Panel>
          </div>
        </div>
        {/* <div className="footer l-box is-center">
          <PermissionCheck usuario={this.state.usuario} />
        </div> */}

      </div>
    );
  }
}
