import React, { Component } from 'react';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import { library } from '@fortawesome/fontawesome-svg-core'
import { fab } from '@fortawesome/free-brands-svg-icons'
import { faHandshake, faHandHoldingUsd, faMoneyCheckAlt } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Panel } from 'primereact/panel';
import { Messages } from 'primereact/messages';
import { ProgressBar } from 'primereact/progressbar';
import { ProgressSpinner } from 'primereact/progressspinner';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import $ from 'jquery';
import { Button } from 'primereact/button';
import { Sidebar } from 'primereact/sidebar';
import { DialogRemun } from '../Folpag/DialogRemun';
import { DialogRemunRpps } from '../Folpag/DialogRemunRpps';
import { DialogPgto } from '../Folpag/DialogPgto';
import { Dialog } from 'primereact/dialog';
import { FormEvtFechamento } from '../Folpag/FormEvtFechamento';
import { TabView, TabPanel } from 'primereact/tabview';
import { BreadCrumb } from 'primereact/breadcrumb';
import { TabInfoComplementar } from '../Folpag/tabs/TabInfoComplementar';
import { TabEvtFechamento } from '../Folpag/tabs/TabEvtFechamento';
import { TabTotalizador } from '../Folpag/tabs/TabTotalizador';
import { TabHistorico } from '../Folpag/tabs/TabHistorico';

import { Checkbox } from 'primereact/checkbox';
import { EnviarFolha } from '../Folpag/btn/EnviarFolha';
import TabGeracaoEvtsPer from './tabs/TabGeracaoEvtsPer';
import { TblImportacao } from '../Importacao/TblImportacao';

export class TblFolhaPagamento extends Component {
  constructor() {
    super();
    library.add(fab, faHandshake, faHandHoldingUsd, faMoneyCheckAlt);
    this.state = {
      welcome: 'Carregando folha de pagamento ...'
      , tblLoading: 'block'
      , InitialLoading: 'block'
      , anoMesApur: null
      , folhaGeralPanel: false
      , respTecnicoPanel: true
      , folhaServidoresPanel: true
      , interval: null
      , eventosSelecionados: []
      , selecionaTodosEvts: false
      , checked: false
      , regPagina: 10
      , pagina: 0
      , warning: false
      , ano: '18'
      , mes: '01'
      , dvImportacao: 'none'
      , divStatusEnvio: 'block'
      , btnFechamento: 'none'
      , fechamentoModal: false
      , statImportacao: 0
      , servidores: []
      , responsavelTecnico: { nome: '', email: '', cpf: '', telefone: '' }
      , autoCompleteServidores: []
      , isAnual: false
      , ldlServidores: true
      , percentEnviados: 0
      , totalRegImpor: 0
      , hash: ''
      , srvPag: 0
      , wzdGeracaoEvento: false
      , folha: null
      , wdwAddServidor: false
      , servidoresFiltrados: null
      , total: 0
    };
    this.actionTemplate = this.actionTemplate.bind(this);
    this.displayValueTemplate = this.displayValueTemplate.bind(this);

    this.renderCheckBoxEnvio = this.renderCheckBoxEnvio.bind(this);
    this.onEventCheckChange = this.onEventCheckChange.bind(this);
    this.onCheckAllChange = this.onCheckAllChange.bind(this);
    //this.listaServidoresFolhaPaginado = this.listaServidoresFolhaPaginado().bind(this);
    //this.buscar = this.buscar.bind(this);
    this.servers = [];
  }
  componentWillMount() {
    this.atualizarLista(false);
  }
  abrirPainelServidores(e) {
    this.setState({ folhaServidoresPanel: e.value })
    this.listaServidoresFolhaPaginado();

  }
  listaServidoresFolhaPaginado() {
    $.ajax({
      url: "/EsocialAPI/folha/trabalhadores/" + this.props.idFolha + "/20/" + this.state.srvPag,
      dataType: 'json',
      success: function (resposta) {
        this.setState({ servidores: resposta.servidores, total: resposta.totalRegistros, ldlServidores: false, tblLoading: 'none' });
      }.bind(this),
      error: function (resposta) {
        this.setState({ wzdGeracaoEvento: true, tblLoading: 'none' });
      }.bind(this),
      beforeSend: function (xhr, settings) { xhr.setRequestHeader('Authorization', 'Bearer ' + localStorage.getItem('auth-token')); }
    });
  }
  atualizarLista(bolOnpage) {
    let pagina = 0;
    if (bolOnpage === false) {
      pagina = this.state.pagina - 1;
      if (pagina < 0) {
        pagina = 0;
      }
    } else {
      pagina = this.state.pagina;
    }
    console.log();

    $.ajax({
      url: "/EsocialAPI/folha/editar/" + this.props.idFolha,
      dataType: 'json',
      success: function (resposta) {
        this.desabilitaLoading();
        let anoBase = new Date(resposta.folha.perApur).getFullYear();
        let mesBase = new Date(resposta.folha.perApur).getMonth() + 1;

        this.setState({ ano: anoBase, mes: mesBase, isAnual:resposta.folha.isAnual, percentEnviados: resposta.folha.percentualEnviado, responsavelTecnico: resposta.folha.responsavelTecnico, folha: resposta.folha });

      }.bind(this),
      beforeSend: function (xhr, settings) { xhr.setRequestHeader('Authorization', 'Bearer ' + localStorage.getItem('auth-token')); }
    });
  }
  buscar(e) {
    e.preventDefault();
    var apuracao = new Date(this.state.anoMesApur);
    var mesinho = apuracao.getMonth() + 1;
    var zeroAEsquerda = "0";
    if (mesinho > 9) {
      zeroAEsquerda = "";
    }
    let anoApur = apuracao.getFullYear().toString().substr(-2);
    let mesApur = zeroAEsquerda + mesinho.toString();
    this.setState({ ano: anoApur, mes: mesApur, tblLoading: 'block' });
    $.ajax({
      url: "/EsocialAPI/folha/" + anoApur + "/" + mesApur + "/0/" + this.state.regPagina + "/" + this.state.servidor,
      dataType: 'json',
      success: function (resposta) {
        if (resposta.percentual === 100) {
          this.setState({ btnFechamento: 'block' });
        } else {
          this.setState({ btnFechamento: 'none' });
        }
        this.desabilitaLoading();
        this.setState({ servidores: resposta.servidores }, this.validaImportacao(resposta));
        this.setState({ ano: resposta.ano, mes: resposta.mes, total: resposta.total, percentEnviados: resposta.percentual, statImportacao: 0, totalRegImpor: 0 });
      }.bind(this),
      beforeSend: function (xhr, settings) { xhr.setRequestHeader('Authorization', 'Bearer ' + localStorage.getItem('auth-token')); }
    });
    //this.atualizarLista(false);
  }
  changeApur() {

  }
  validaImportacao(resposta) {
    console.log(resposta.servidores.length);
    if (resposta.servidores.length === 0) {
      this.setState({ dvImportacao: 'block', divStatusEnvio: 'none' });
    } else {
      this.setState({ dvImportacao: 'none', divStatusEnvio: 'block' });
    }
  }
  desabilitaLoading() {
    this.setState({ tblLoading: 'none', InitialLoading: 'none' });
  }
  onPage(event) {
    this.setState({ tblLoading: 'block', ldlServidores: true });
    setTimeout(() => {
      const startIndex = event.first;
      this.setState({
        first: startIndex,
        srvPag: event.page

      });
      this.listaServidoresFolhaPaginado();
    }, 250);
  }

  actionTemplate(rowData) {
    try {
      let retorno = '';
      let evtRmnRPPS = rowData.evtRmnRPPS;
      let remunRppsTot = true;
      if (evtRmnRPPS !== null) {
        if (evtRmnRPPS.enviado === 'PROCESSADO') {
          remunRppsTot = false;
        }
      }

      let remun = rowData.evtRmnRGPS;
      let remunTot = true;
      if (remun !== null) {
        if (remun.enviado === 'PROCESSADO') {
          remunTot = false;
        }
      }

      let pgto = rowData.evtRemun;
      console.log(pgto);
      let pgtoTot = true;
      if (pgto.enviado === 'PROCESSADO') {
        pgtoTot = false;
      }
      if (evtRmnRPPS.id == null) {
        retorno = (
          <div>
            <DialogRemun eventoFila={remun} remunTot={remunTot} nome={rowData.nome} matricula={rowData.matricula} />
            <DialogPgto eventoFila={pgto} remunTot={remunTot} pgtoTot={pgtoTot} nome={rowData.nome} matricula={rowData.matricula} />
          </div>
        )
      } else {
        retorno = (
          <div>
            <DialogRemunRpps eventoFila={evtRmnRPPS} remunTot={remunRppsTot} nome={rowData.nome} matricula={rowData.matricula} />
            <DialogPgto eventoFila={pgto} remunTot={remunTot} pgtoTot={pgtoTot} nome={rowData.nome} matricula={rowData.matricula} />
          </div>
        )
      }
      return retorno

    } catch (e) {
      console.log(e);

    }
  }
  teste(rowData) {
    console.log(rowData);
  }
  changeAnoMesApur(rowData, column) {
    let retorno = '';
    retorno = (
      <div>
        <strong>{this.state.mes}/{this.state.ano}</strong>
      </div>
    )
    return retorno
  }
  carregaEventos() {
    $.ajax({
      url: "/EsocialAPI/api/importacao/periodico/preview",
      method: 'POST',
      data: JSON.stringify({ mesAnoApuracao: this.state.ano + "-" + this.state.mes }),
      contentType: "application/json",
      dataType: 'json',
      success: function (resposta) {
        this.setState({ hash: resposta.hash }, () => this.iniciarImportEventos());
        this.setState({ totalRegImpor: resposta.registros }, () => this.atualizaBarra());

      }.bind(this),
      beforeSend: function (xhr, settings) { xhr.setRequestHeader('Authorization', 'Bearer ' + localStorage.getItem('auth-token')); }
    });
  }
  iniciarImportEventos() {
    $.ajax({
      url: "/EsocialAPI/api/importacao/periodico/",
      method: 'POST',
      data: JSON.stringify({ mesAnoApuracao: this.state.ano + "-" + this.state.mes, tokenImportacao: this.state.hash }),
      contentType: "application/json",
      dataType: 'json',
      success: function (resposta) {
      },
      beforeSend: function (xhr, settings) { xhr.setRequestHeader('Authorization', 'Bearer ' + localStorage.getItem('auth-token')); }
    });
  }
  atualizaBarra() {
    this.consultaImportacao();
    var intervalo = setInterval(() => {
      this.consultaImportacao();
    }, 2000);
    this.setState({ interval: intervalo })
  }
  consultaImportacao() {
    if (this.state.statImportacao !== 100) {
      $.ajax({
        url: "/EsocialAPI/api/importacao/consulta/",
        method: 'POST',
        data: JSON.stringify({ tokenImportacao: this.state.hash }),
        contentType: "application/json",
        dataType: 'json',
        success: function (resposta) {
          var percentual = Math.round((resposta / this.state.totalRegImpor) * 100);
          this.setState({ statImportacao: percentual });
        }.bind(this),
        beforeSend: function (xhr, settings) { xhr.setRequestHeader('Authorization', 'Bearer ' + localStorage.getItem('auth-token')); }
      });
    } else {
      clearInterval(this.state.interval);
    }
  }
  displayValueTemplate(value) {
    return (
      <React.Fragment>
        {value}%
      </React.Fragment>
    );
  }
  habilitaDetalhesServidor() {

    $("#divDetalheServidor").slideDown();
  }
  leaveImportacao(e) {
    this.setState({ warning: false });
    //this.buscar(e);
  }
  gerarEventos() {

    if (this.state.statImportacao !== 100) {
      $.ajax({
        url: "/EsocialAPI/folha/gerarFolha/" + this.state.folha.idFolhaPagamento,
        method: 'post',
        contentType: "application/json",
        dataType: 'json',
        success: function (resposta) {
          console.log(resposta);
        },
        beforeSend: function (xhr, settings) { xhr.setRequestHeader('Authorization', 'Bearer ' + localStorage.getItem('auth-token')); }
      });
    }
    this.setState({ wzdGeracaoEvento: false });
  }
  onEventCheckChange(e) {
    let selectedEvents = [...this.state.eventosSelecionados];
    console.log(e.checked);
    if (e.checked)
      selectedEvents.push(e.value);
    else
      selectedEvents.splice(selectedEvents.indexOf(e.value), 1);

    this.setState({ eventosSelecionados: selectedEvents });
  }
  renderCheckBoxEnvio(rowData, column) {
    const retorno = (
      <Checkbox onChange={this.onEventCheckChange} value={rowData} checked={this.state.eventosSelecionados.indexOf(rowData) !== -1}></Checkbox>
    )
    return retorno;
  }
  renderCheckSelecionaTodos() {
    return <Checkbox onChange={this.onCheckAllChange} checked={this.state.selecionaTodosEvts}></Checkbox>
  }
  onCheckAllChange(e) {
    if (e.checked) {
      console.log(e.checked);
    }
    this.setState({ selecionaTodosEvts: e.checked });
    //this.setState({eventosSelecionados: []});
  }
  render() {
    const items = [
      { label: 'Home', icon: 'pi pi-home', url: '/home' },
      { label: 'Folha de Pagamento', icon: 'pi pi-home', url: '/FolhaPagamento' }
    ];
    return (
      <div>

        <BreadCrumb home="principal" model={items} style={{ marginBottom: '20px' }} />
        <Messages ref={(el) => this.messages = el}></Messages>
        <Panel header={"Folha de Pagamento :" + this.state.mes + "/" + this.state.ano} >
          <h5>Gerenciamento de Eventos de Folha de Pagamento</h5>

          <Panel header="Status Folha de Pagamento" style={{ marginTop: '15px' }} toggleable collapsed={this.state.folhaGeralPanel} onToggle={(e) => this.setState({ folhaGeralPanel: e.value })}>
            <div id="divStatusEnvio" style={{ display: this.state.divStatusEnvio }}>
              <p>
                <em>Situação dos eventos do período de Apuração <strong>{this.state.mes + "/" + this.state.ano}</strong></em>
              </p>
              <div id="divEnviarFechamento" style={{ textAlign: 'center' }}>
                <Dialog header="Enviar Evento 1299" visible={this.state.fechamentoModal} style={{ width: '50vw' }} modal={true} onHide={() => this.setState({ fechamentoModal: false })}>
                  <FormEvtFechamento anoApuracao={this.state.ano} mesApuracao={this.state.mes} />
                </Dialog>
                {/* <Button onClick={(e) => this.setState({fechamentoModal:true})} label='FontAwesome'><FontAwesomeIcon icon={faHandshake} />S1299</Button>&nbsp; */}
              </div>
            </div>
            <div id="divImportaEvtFolha" style={{ display: this.state.dvImportacao }}>
              <em>
                <p>Nenhum evento de Folha foi importado para o período <strong>{this.state.mes}/{this.state.ano}</strong>.</p>
                <p>Para importar os eventos de folha no período referido clique no botão abaixo.</p>
                <Button label="Importar" icon="pi pi-upload" onClick={(e) => this.setState({ warning: true })} />
              </em>
            </div>
            <div>
              <TabView activeIndex={this.state.activeIndex} onTabChange={(e) => this.setState({ activeIndex: e.index })}>
                {/* <TabPanel header="Situação eventos de folha">
                  <TabGeracaoEvtsPer idFolha={this.props.idFolha} />
                </TabPanel> */}
                <TabPanel header="Geração Eventos Periódicos">
                  <h3>Geração de eventos Periódicos</h3>
                  <p style={{ fontStyle: 'italic', fontWeight: 'bold' }}>
                    Os eventos de folha {'(periódicos)'} para o período em questão podem ser gerados através dessa tela. Os seguintes eventos serão gerados: 1200, 1202, 1207, 1210.
                  </p>
                  <TblImportacao idGrupo="3" idFolha={this.props.idFolha}/>
                </TabPanel>
{/*                 <TabPanel header="Evento Info. Complementar">
                  <TabInfoComplementar />
                </TabPanel>
                <TabPanel header="Evento de Fechamento">
                  <TabEvtFechamento idFolha={this.props.idFolha} />
                </TabPanel>
                <TabPanel header="Evento de Reabertura">
                  <TabEvtFechamento idFolha={this.props.idFolha} />
                </TabPanel> */}
                <TabPanel header="Totalizadores">
                  <TabTotalizador anoApuracao={this.state.ano} mesApuracao={this.state.mes} isAnual={this.state.isAnual}/>
                </TabPanel>
                <TabPanel header="Histórico">
                  <TabHistorico />
                </TabPanel>
              </TabView>
            </div>
          </Panel><br />

          {/* 
          <Panel header="Servidores" toggleable collapsed={this.state.folhaServidoresPanel} onToggle={(e) => this.abrirPainelServidores(e) }>
          <div id="tblEventosPaginada">

                  
            <ProgressBar mode="indeterminate" style={{height: '15px',display:this.state.tblLoading}} id="tblLoading" />
            <DataTable value={this.state.servidores} 
            emptyMessage="Nenhum Evento de folha Gerado" 
            className="p-datatable-sm"
            paginator={true} 
            totalRecords={this.state.total}
            loading={this.state.ldlServidores} 
            rows={this.state.regPagina} 
            first={this.state.first} 
            lazy={true}
            selectionMode="checkbox"
            selection={this.state.eventosSelecionados} 
            onSelectionChange={e => this.setState({ eventosSelecionados: e.value })} 
            onPage={this.onPage.bind(this)} >

                <Column field="matricula" header="Matricula" style={{width:"5%"}} />
                <Column field="nome" header="Nome" style={{width:"47%"}} />
                <Column body={this.changeAnoMesApur.bind(this)} header="Prd. Apuracao" style={{width:"5%"}} />
                <Column body={this.actionTemplate} header="Eventos" style={{width:"8%",textAlign:'center'}} />
            </DataTable>
          </div>
          </Panel>
          */}
        </Panel>
      </div>
    );
  }
}
