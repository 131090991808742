import React, { Component } from 'react';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import { Messages } from 'primereact/messages';
import { MenuBar } from '../../../componentes/MenuBar';
import { Panel } from 'primereact/panel';
import { BreadCrumb } from 'primereact/breadcrumb';
import { Card } from 'primereact/card';
import $ from 'jquery';
import { Accordion, AccordionTab } from 'primereact/accordion';
import ReactHtmlParser from 'react-html-parser';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { Editor } from 'primereact/editor';
import { TabView, TabPanel } from 'primereact/tabview';
import ScrollMenu from 'react-horizontal-scrolling-menu';
import { Arrow } from '../../../componentes/Arrow';
import { GridLegendaCor } from '../../../componentes/GridLegendaCor';
import { FileUpload } from 'primereact/fileupload';
import {Ajuda} from '../../Processamento/preview/Ajuda';
import {ProgressBar} from 'primereact/progressbar';
import Alert from 'react-bootstrap/Alert';
import ajudaService from '../../../services/ajudaService';
import eventoService from '../../../services/eventoService';
import catalogoService from '../../../services/catalogoService';


export class AjudaEvento extends Component {
	constructor() {
		super();
		this.state = {
			hashImportacao:'',
			visible: false,
			totalAjudasAImportar:0,
			statImportacao:0,
			home: { icon: 'pi pi-home' },
			formdocIndex: 0,
			activeIndex: null,
			activeIndexTab: 0,
			agendamentos: [],
			tipoEvento: "1",
			catalogo: {
				versao: null,
				dataImplementacao: null,
				dataVigencia: null,
				conteudo: ""
			},
			evento: {
				idEventoEsocial: 0,
				nomeEvento: "",
				codeventomensageria: "",
				eventoImplementado: false,
				leiauteImportado: false,
				cor: '',
				tipoEvento: {
					descricao: ''
				}
			},
			eventosMap:[],
			formDocumentacao: false,
			documentacao: {
				novaDoc: false,
				conceito: 'Nenhum conceito registrado',
				informacaoAdicional: '',
				obrigacao: '',
				prazo: '',
				preRequisito: ''
			},
			eventos: [],
			prerequisito: '',
			informacaoAdicional: '',
			obrigacao: '',
			prazo: ''.descricao,
			cores: [
				'#CA2E55',
				'#7DAF9C',
				'#6D676E',
				'#00A7E1',
				'#BFAB25',
				'#00f',
				'#0f0',
				'#f00',
			]
		};
		this.salvarDocumentacao = this.salvarDocumentacao.bind(this);
		this.uploadArquivo = this.uploadArquivo.bind(this);
		this.cargaImportacaoAjuda = this.cargaImportacaoAjuda.bind(this);
		this.showMessage = this.showMessage.bind(this);
		this.showSucessDialog = this.showSucessDialog.bind(this);
	}
	salvarDocumentacao() {
		var eventoEsocial = { idEventoEsocial: this.state.evento.idEventoEsocial };
		var evento = {
			eventoEsocial: eventoEsocial,
			campoMentorh: "",
			conceito: this.state.documentacao.conceito,
			obrigacao: this.state.documentacao.obrigacao,
			prazo: this.state.documentacao.prazo,
			preRequisito: this.state.documentacao.preRequisito,
			informacaoAdicional: this.state.documentacao.informacaoAdicional
		};
		console.log(evento);
		//var dados = JSON.stringify(evento);
	}
	cargaImportacaoAjuda(){
		let obj = {hashImportacao : this.state.hashImportacao};
		ajudaService.cargaAjuda(obj, this.atualizaEventos());
    }
	uploadArquivo(event) {
		this.showMessage('info', 'Efetuando Upload', 'Por favor, aguarde.')
		var formData = new FormData();
		formData.append('file', event.files[0]);
		formData.append('codeventomensageria', this.state.evento.codeventomensageria);

		ajudaService.uploadArquivo(formData, (resposta) => {
            this.setState({ hashImportacao: resposta.hash }, () => this.cargaImportacaoAjuda());
            this.setState({ totalAjudasAImportar: resposta.registros }, () => this.atualizaBarra());
        });
	}
	atualizaEventos() {
		eventoService.carregarEventoAtual((resposta) => {
            const evtMapeado = this.mapeaEventos(resposta);
            this.setState({ eventos: resposta, eventosMap: evtMapeado }, () => {
                this.setaEventoInicial(evtMapeado, 7);
            });
        });
	}

	consultaImportacao() {
        if (this.state.statImportacao !== 100) {
            ajudaService.consultaImportacao(this.state.hashImportacao, (resposta) => {
                const percentual = Math.round((resposta / this.state.totalAjudasAImportar) * 100);
                this.setState({ statImportacao: percentual }, () => this.showSucessDialog(percentual));
            });
        } else {
            clearInterval(this.state.interval);
        }
    }

	atualizaBarra(){
		this.consultaImportacao();
		var intervalo = setInterval(() => {
			this.consultaImportacao();
		  }, 500);
    	this.setState({interval:intervalo });
  	}
	componentDidMount() {
		catalogoService.getCatalogoAtual((resposta) => {
            this.setState({ catalogo: resposta });
        });

		this.atualizaEventos();
	}
	mapeaEventos(eventos){
		eventos = this.ordenaListaEventos(eventos);
		var listaObjCorTipo = this.atribuiCorTipo(eventos);
		 var eventosMap = eventos.map((evento)=>{
			var leiauteImportado=false;
			var cor='';
			if(evento.ajuda.length>0){
				console.log('Entrei');
				leiauteImportado=true;
			}
			listaObjCorTipo.forEach((objCorTipo)=>{
				if(objCorTipo.legenda === evento.tipoEvento.descricao){
					cor=objCorTipo.cor;
				}
			});
			var eventoMap = {
				idEventoEsocial: evento.idEventoEsocial,
				nomeEvento: evento.nomeEvento,
				codeventomensageria: evento.codeventomensageria,
				eventoImplementado: evento.eventoImplementado,
				leiauteImportado: leiauteImportado,
				cor: cor,
				tipoEvento: evento.tipoEvento
			}
			return eventoMap;
		});
		console.log(eventosMap);
		return eventosMap;
	}
	sltChange(event) {
		console.log(event);
		this.setState({ evento: event });
		if (event.documentacao != null) {
			this.setState({ documentacao: event.documentacao, novaDoc: true });
		} else {
			this.setState({ documentacao: { conceito: 'Nenhum conceito registrado', informacaoAdicional: '', obrigacao: '', prazo: '', preRequisito: '' }, novaDoc: false });
		}
	}
	encodeUtf8(s) {
		return unescape(encodeURIComponent(s));
	}
	decodeUtf8(s) {
		return decodeURIComponent(escape(s));
	}

	montaListaSelect(eventos) {
		var lista = [];
		eventos.forEach((element, index, array) => {
			lista.push({ label: element.codeventomensageria, value: element.idEventoEsocial });
		});
		return lista;
	}

	ordenaListaEventos(eventos) {
		eventos.sort((a, b) => {
			if (a.tipoEvento.grupo < b.tipoEvento.grupo) {
				return -1;
			}
			if (a.tipoEvento.grupo > b.tipoEvento.grupo) {
				return 1;
			}
			return 0;
		});
		return eventos;
	}
	
	setaEventoInicial(eventos, index) {
		console.log(eventos[index]);
		this.setState({evento: eventos[index]});
	}
	
	montaListaSideMenu() {
		return this.state.eventosMap.map((evt) => {
			return <Button onClick={() => {this.setState({evento: evt}); console.log(evt)}} label={evt.codeventomensageria} key={evt.idEventoEsocial} style={{ 'padding': '-5px', 'margin': '2px', 'backgroundColor': evt.cor }} tooltip={evt.nomeEvento} tooltipOptions={{ 'position': 'bottom' }} />
		});
	}
	atribuiCorTipo(eventos) {
		var listaTipo = eventos.map((element) => {
			return element.tipoEvento.descricao;
		});
		var listaTipoUnico = listaTipo.filter((element, index) => {
			return listaTipo.indexOf(element) === index;
		});
		var listObjLeg = listaTipoUnico.map((element, index) => {
			return { legenda: element, cor: this.state.cores[index] }
		});
		return listObjLeg;
	}
	displayValueTemplate(value) {
    	return (
        	<React.Fragment>
            	{value}%
        	</React.Fragment>
    	);
	}
	showMessage(severity, summary, detail) {
		this.messages.show({ severity, summary, detail });
	}

	showSucessDialog(percentual){
		console.log(percentual);
		if(percentual>=100){
			this.setState({visible: true});
		}
	}
	renderLeiaute(evt) {
		console.log(evt);
		if (evt.leiauteImportado === false) {
			return (
				<Card title="Importar Leiaute">
					<div>
						<p>Não foi possível encontrar o Leiaute do evento <span style={{ 'fontWeight': 'bold' }}>S{this.state.evento.codeventomensageria} - {this.state.evento.nomeEvento}</span> da versão vigente no sistema. Deseja importar?</p>
						<em>Obs: certifique-se de que o leiaute está no formato xls e que a formatação esteja correta.</em>
					</div>
					<Messages ref={(el) => this.messages = el}></Messages>
					<ProgressBar id="progressBar" value={this.state.statImportacao} style={{marginTop: '3px', marginBottom: '3px'}} displayValueTemplate={this.displayValueTemplate}></ProgressBar>
					<div style={{ 'marginTop': '20px' }}>
						<FileUpload
							name="file"
							mode="basic"
							url="./upload"
							auto={true}
							customUpload={true}
							uploadHandler={this.uploadArquivo}
							chooseLabel="Importar"
							style={{ 'display': 'inlineBlock' }}
						>
						</FileUpload>

					</div>


				</Card>
			);
		} else {
			return <Ajuda idEventoEsocial={this.state.evento.idEventoEsocial} />
		}
	}
	render() {
		const items = [
			{ label: 'Home', icon: 'pi pi-home', url: '/home' },
			{ label: 'Administração', icon: 'pi pi-home', url: '/home' },
			{ label: 'Ajuda', icon: 'pi pi-home', url: '/home' },
			{ label: 'Ajuda Evento', icon: 'pi pi-home', url: '/EsocialReact/administracao/ajudaevento' }
		];

		const itensSideMenu = this.montaListaSideMenu(this.state.eventos);
		const arrowLeft = <Arrow direction="left" />;
		const arrowRight = <Arrow direction="right" />;
		return (
			<div>
				<Dialog visible={this.state.visible} header='Importação realizada' style={{width: '50vw'}} modal={true} onHide={() => {this.setState({visible: false}); window.location.reload(); }}>
					<Alert variant="success">
						<h1 style={{textAlign:'center'}}>Leiaute Importado com Sucesso!</h1>
					</Alert>
				</Dialog>
				<MenuBar />
				<div className="content">
					<div className="content align-center">
						<Messages ref={(el) => this.messages = el}></Messages>
						<div className="pure-u-5-5">
							<br />
							<Panel header="Ajuda de Eventos" >
								<BreadCrumb home="principal" model={items} />
								<div>
									<br />
									<div id="ajuda-principal">
										<div id="cabecalho-ajuda-principal">
											<h3>Catalogo {this.state.catalogo.versao}</h3>
											<em>Vigente no período de: {new Date(this.state.catalogo.dataImplantacao).toLocaleString()} até {new Date(this.state.catalogo.dataVigencia).toLocaleString()}</em>
										</div>
										<div style={{ 'marginBottom': '20px', 'marginTop': '20px' }}>
											<GridLegendaCor dados={this.atribuiCorTipo(this.state.eventos)} />

											{/* <SelectButton value={this.state.evento} options={this.montaListaSelect(this.state.eventos)} onChange={(e) => this.setState({city: e.value})}></SelectButton> */}
											<ScrollMenu
												data={itensSideMenu}
												arrowLeft={arrowLeft}
												arrowRight={arrowRight}
											/>
											{/* <Dropdown optionLabel="codeventomensageria" dataKey="idEventoEsocial" value={this.state.evento} options={this.state.eventos} onChange={(e) => { this.sltChange(e.value) }} placeholder="Escolha um evento para Obter a Ajuda" /> */}
										</div>

										<div>
											<Panel header={this.state.evento.codeventomensageria + ' - ' + this.state.evento.nomeEvento}>
												<TabView>
													<TabPanel header="Leiaute">
														<div id="ajuda-leiaute">
															{this.renderLeiaute(this.state.evento)}
														</div>
													</TabPanel>
													<TabPanel header="Orientações">
														<div id="ajuda-orientacao">

															<Card title={"Orientações para o " + this.state.evento.codeventomensageria} subTitle={this.state.evento.nomeEvento}>
																<Accordion activeIndex={this.state.activeIndex} onTabChange={(e) => this.setState({ activeIndex: e.index })}>
																	<AccordionTab header="Info">
																		<label>Grupo : </label> {this.state.evento.tipoEvento.descricao} <br />
																		<label>Classe do Evento : </label> {this.state.evento.classeEvento}<br />
																		<label>Evento Implementado : </label>{this.state.evento.eventoImplementado}<br />
																	</AccordionTab>
																	<AccordionTab header="Documentação">
																		<div>
																			<Dialog header="Cadastro Documentação" dismissableMask={true} visible={this.state.formDocumentacao} style={{ width: '50vw' }} modal={true} onHide={() => this.setState({ formDocumentacao: false })}>
																				<div>
																					<TabView activeIndex={this.state.activeIndexTab} onTabChange={(e) => { console.log(e); this.setState({ activeIndexTab: e.index }); }}>
																						<TabPanel header="Conceito">
																							<h3>Conceito</h3>
																							<Editor
																								style={{ height: '320px' }}
																								value={this.state.documentacao.conceito}
																								onTextChange={(e) => this.setState({ documentacao: { conceito: e.htmlValue } })}
																							/>
																						</TabPanel>
																						<TabPanel header="Obrigação">
																							<h3>Obrigação</h3>
																							<Editor
																								style={{ height: '320px' }}
																								value={this.state.documentacao.obrigacao}
																								onTextChange={(e) => this.setState({ documentacao: { obrigacao: e.htmlValue } })}
																							/>
																						</TabPanel>
																						<TabPanel header="Prazo">
																							<h3>Prazo</h3>
																							<Editor
																								style={{ height: '320px' }}
																								value={this.state.documentacao.prazo}
																								onTextChange={(e) => this.setState({ documentacao: { prazo: e.htmlValue } })}
																							/>
																						</TabPanel>
																						<TabPanel header="Pré Requisito">
																							<h3>Pré Requisito</h3>
																							<Editor
																								style={{ height: '320px' }}
																								value={this.state.documentacao.preRequisito}
																								onTextChange={(e) => this.setState({ documentacao: { preRequisito: e.htmlValue } })}
																							/>
																						</TabPanel>
																						<TabPanel header="Informação Adicional">
																							<h3>Informação Adicional</h3>
																							<Editor
																								style={{ height: '320px' }}
																								value={this.state.documentacao.informacaoAdicional}
																								onTextChange={(e) => this.setState({ documentacao: { informacaoAdicional: e.htmlValue } })}
																							/>
																						</TabPanel>
																					</TabView>

																					{/* <Accordion activeIndex={this.state.formdocIndex} onTabChange={(e) => this.setState({formdocIndex: e.index})}>
										<AccordionTab header="Conceito">
											<label>Conceito : </label> 
											<Editor style={{height:'320px'}} value={this.state.conceito} onTextChange={(e) => this.setState({conceito: e.htmlValue})} /> <br />
										</AccordionTab>
										<AccordionTab header="Obrigação"><label>Obrigação : </label> <Editor style={{height:'320px'}} value={this.state.obrigacao} onTextChange={(e) => this.setState({obrigacao: e.htmlValue})} /><br /></AccordionTab>
										<AccordionTab header="Prazo"><label>Prazo : </label> <Editor style={{height:'320px'}} value={this.state.prazo} onTextChange={(e) => this.setState({prazo: e.htmlValue})} /><br /></AccordionTab>
										<AccordionTab header="Pré Requisito"><label>Pré Requisito : </label> <Editor style={{height:'320px'}} value={this.state.preRequisito} onTextChange={(e) => this.setState({preRequisito: e.htmlValue})} /> <br /></AccordionTab>
										<AccordionTab header="Informação Adicional"><label>Informação Adicional : </label> <Editor style={{height:'320px'}} value={this.state.informacaoAdicional} onTextChange={(e) => this.setState({informacaoAdicional: e.htmlValue})} /> <br /></AccordionTab>
										</Accordion> */}
																					<Button label="Salvar" icon="pi pi-save" iconPos="left" onClick={(e) => this.salvarDocumentacao()} />
																				</div>
																			</Dialog>
																			<Button label="Novo" icon="pi pi-save" iconPos="left" disabled={this.state.novaDoc} onClick={(e) => this.setState({ formDocumentacao: true })} />
																		</div>
																		<label>Pré Requisito : </label> {ReactHtmlParser(this.state.documentacao.preRequisito)} <br />
																		<label>Conceito : </label> {ReactHtmlParser(this.state.documentacao.conceito)}<br />
																		<label>Informação Adicional : </label> {ReactHtmlParser(this.state.documentacao.informacaoAdicional)}<br />
																		<label>Obrigação : </label> {ReactHtmlParser(this.state.documentacao.obrigacao)}<br />
																		<label>Prazo : </label> {ReactHtmlParser(this.state.documentacao.prazo)}<br />
																	</AccordionTab>
																	<AccordionTab header="Ajuda dos Tags">

																	</AccordionTab>
																</Accordion>
																{this.state.conteudo}
															</Card>
														</div>
													</TabPanel>
												</TabView>
											</Panel>
										</div>



									</div>
								</div>
							</Panel>
						</div>
					</div>

				</div>
				<div className="footer l-box is-center">
					Copyright © Todos os direitos reservados.
            </div>

			</div>
		);
	}
}

