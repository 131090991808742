import React, { Component } from "react";
import "./index.css";
import "./login.css";
import { Messages } from 'primereact/messages';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import {Password} from 'primereact/password';
import $ from 'jquery';


export class Login extends Component {

  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
    this.showError = this.showError.bind(this);
    this.state = {
      usuario: "",
      password: "",
      usuarioMentorh: "",
      senhaMentorh: "",
      options: true,
      resetSenha: false,
      importacaoMentorh: false,
      emailReset: '',
      lista: "",
      erro: ""
    };
    this.trataErrosLogin = this.trataErrosLogin.bind(this);
    this.habilitaOpcoes = this.habilitaOpcoes.bind(this);
    this.importarUsuarioMentorh = this.importarUsuarioMentorh.bind(this);
  }

  showError(erro) {
    console.log(erro);
    this.messages.show({ severity: 'error', summary: 'Desculpe: ', detail: erro });
  }
  showSucesso(Mensagem) {
    this.messages.show({ severity: 'success', summary: 'Sucesso! : ', detail: Mensagem });
  }
  validateForm() {
    return this.state.usuario.length > 0 && this.state.password.length > 0;
  }

  handleChange = event => {
    this.setState({
      [event.target.id]: event.target.value
    });
  }
  trataErrosLogin(error) {
    try {
      switch (error) {
        case 401:
          return 'Usuário/senha inválidos!';
        case 404:
          return 'Erro ao autenticar.';
        case 500:
          return 'API Indisponível.';
        case 504:
          return 'API Indisponível.';
        default:
          console.log('Desculpe, erro: ${error}.');
      }
    } catch (erros) {
      console.log('Erro');
    }
  }
  importarUsuarioMentorh(){
    try{
      //let usuarioMentor = ;
      let dados = JSON.stringify({login:this.state.usuarioMentorh,senha:this.state.senhaMentorh});
      $.ajax({
        type: "POST",
        url:"/EsocialAPI/recuperar/mentorh",
        dataType: 'json',
        data: dados,
        success:function(resposta){   
            if(resposta.json!=="null"){
              if(resposta.codigoStatus!==1){
                this.showError(" "+resposta.mensagemStatus);
                this.setState({importacaoMentorh:false});
              }else{
                this.showSucesso("Usuário importado com sucesso!");
                this.setState({importacaoMentorh:false,usuario:this.state.usuarioMentorh,senha:this.state.senhaMentorh});
              }
            }else{
              this.showError(resposta);
            }
        }.bind(this),
        error:function(resposta){       
            this.showError(" " + resposta.message); 
          //this.setState({wzdGeracaoEvento:true,tblLoading:'none'});
        }.bind(this),
        beforeSend: function(xhr, settings) { xhr.setRequestHeader('Authorization','Bearer ' + localStorage.getItem('auth-token')); }
      });

    }catch(error){
      console.log(error);
    }
  }
  habilitaOpcoes() {
    try {
      if (this.state.options) {
        console.log('Mostrar');
        $('#dvEsqueciSenha').show();
        this.setState({ options: false });
      } else {
        console.log('Esconder');
        $('#dvEsqueciSenha').hide();
        this.setState({ options: true });
      }

    } catch (error) {
      console.log(error);
    }
  }
  autenticar(event) {
    event.preventDefault();
    const requestInfo = {
      method: 'POST',
      body: JSON.stringify({ login: this.login.value, senha: this.password.value }),
      headers: new Headers({
        'Content-type': 'application/json'
      })
    };
    fetch('/EsocialAPI/auth', requestInfo)
      .then(response => {
        console.log(response.statusText);
        if (response.ok) {
          return response.text();
        } else {
          throw new Error(this.trataErrosLogin(response.status));
        }
      })
      .then(token => {
        var tokn = JSON.parse(token);
        localStorage.setItem('auth-token', tokn.token);
        localStorage.setItem('login', tokn.login);
        localStorage.setItem('cliente', tokn.cliente);
        this.props.history.push('/home');
      })
      .catch(error => {
        console.log(error);
        this.showError(" " + error.message);
      });
  };
  render() {
    const footer = (
      <div>
        <Button label="Enviar" />
      </div>
    );
    return (
      <div className="Login">
        <div className="wrapper fadeInDown">
          <div id="formContent">
            <div className="fadeIn first" style={{ marginBottom: '25px', marginTop: '25px' }}>
              <img src="esocial-logo.png" id="icon" alt="User Icon" />
            </div>
            <div>
              <Messages ref={(el) => this.messages = el} />
              <form onSubmit={this.autenticar.bind(this)}>
                <input type="text" id="login" className="fadeIn second" name="login" placeholder="usuario" ref={(input) => this.login = input} />
                <input type="password" id="password" className="fadeIn third" name="login" placeholder="senha" ref={(input) => this.password = input} />
                <input type="submit" className="fadeIn fourth" value="Entrar" />
              </form>

            </div>
            <div style={{ textAlign: 'left' }}>
              <Button label="Outras opções." className="p-button-info p-button-text" onClick={(e) => this.habilitaOpcoes()} icon="pi pi-cog" />
            </div>
            <div style={{ display: 'none' }} id="dvEsqueciSenha">
              <ul style={{ textAlign: 'left' }}>
                <li style={{ listStyle: 'none' }}>
                  <Dialog header="Redefinição de senha" visible={this.state.resetSenha} style={{ width: '50vw' }} footer={footer} closable onHide={(e) => this.setState({ resetSenha: false })} >
                    <p>Digite o nome do usuário e clique em enviar.</p>
                    <span className="p-input-icon-left">
                      <i className="pi pi-envelope" />
                      <InputText value={this.state.emailReset} onChange={(e) => this.setState({ emailReset: e.value })} />
                    </span>
                    <br />

                  </Dialog>
                  {/* <Button label="Esqueci minha senha." className="p-button-info p-button-text" icon="pi pi-question-circle" onClick={(e) => this.setState({ resetSenha: true })} /> */}
                </li>
                <li style={{ listStyle: 'none' }}>
                  <Dialog header="Importação MENTORH " visible={this.state.importacaoMentorh} style={{ width: '50vw' }} closable onHide={(e) => this.setState({ importacaoMentorh: false })} >
                    <div style={{ textAlign: 'center',border:'1px solid #ccc' }}>
                      <em>Favor digite o usuário e senha do MENTORH para a devida importação para a mensageria.</em>
                      <div>
                        <span className="p-input-icon-left">
                          <i className="pi pi-user" />
                          <InputText value={this.state.usuarioMentorh} onChange={(e) => this.setState({ usuarioMentorh: e.target.value })} />
                        </span>
                      </div>
                      <div style={{marginTop:'22px'}}>
                        <span className="p-input-icon-left">
                          <Password value={this.state.senhaMentorh} onChange={(e) => this.setState({ senhaMentorh: e.target.value })} weakLabel="Fraco" mediumLabel="Regular" strongLabel="Forte" promptLabel="Digite sua senha" />
                        </span>
                      </div>
                      <div style={{marginTop:'22px',marginBottom:'22px'}}>
                        <Button label="Importar" icon="pi pi-save" iconPos="left" onClick={(e)=> this.importarUsuarioMentorh()} />
                      </div>
                    </div>
                  </Dialog>
                  <Button label="Usuário MENTORH" className="p-button-info p-button-text" icon="pi pi-download" onClick={(e) => this.setState({ importacaoMentorh: true })} />
                </li>
              </ul>
            </div>
            <div id="formFooter" style={{ marginTop: '20px' }}>
              <img src="img/logoLimpaMentorh.png" id="icon" alt="User Icon" style={{ width: '80%' }} />
            </div>

          </div>
        </div>
      </div>
    );
  }
}
