import React, { Component, useEffect, useState } from 'react';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import $ from 'jquery';
import { Button } from "primereact/button";
import { Dialog } from 'primereact/dialog';
import { ProgressBar } from 'primereact/progressbar';
import { Toast } from 'primereact/toast';

const BotaoReenvioEventosLote = (props) => {

    const [isVisible, setIsVisible] = useState(false)
    const [mensagem, setMensagem] = useState("")
    const [progressBarVisible, setProgressBarVisible] = useState(true)

    function onHide(){
        setIsVisible(false)
        props.atualizarGrid(false)
    }

    function showToast(arrMensagens) {
        //props.toast.show(arrMensagens);
    }

    function reenvioEventoLote(idLote) {

        setMensagem("Reenviando eventos em um novo Lote, favor aguarde alguns instantes...")
        setIsVisible(true)
        $.ajax({
            type: "GET",
            url: "/EsocialAPI/EnviarEventosComIdentificadorLote/"+idLote,
            dataType: 'text',
            success: function (resposta) {
                console.log(resposta);
                setTimeout(onHide, 10000);
                setMensagem(resposta.mensagem)

            },
            error: function (resposta){
                console.log(resposta);
                
                setProgressBarVisible(false)
                setMensagem(resposta.mensagem)
                setTimeout(onHide, 4000);
            },
            beforeSend: function (xhr, settings) { xhr.setRequestHeader('Authorization', 'Bearer ' + localStorage.getItem('auth-token')); }
        });
    }

    return (

        <div>

            <Button className="p-button-warning" type="button" icon="pi pi-angle-double-up" onClick={(e) => reenvioEventoLote(props.idLote)} tooltip="Reenviar eventos em um novo lote" tooltipOptions={{position: 'top'}} disabled={props.disabled}></Button>
            
            <Dialog header="Reenviando Lote" visible={isVisible} style={{ width: '50vw' }} onHide={() => onHide()} position={"top"} draggable={false} resizable={false} dismissableMask={false} closable={false}>
                <p>{mensagem}</p>
                {progressBarVisible?<ProgressBar mode="indeterminate" />:""}
            </Dialog>

        </div>
    )
}

export default BotaoReenvioEventosLote;
