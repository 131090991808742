import React, { Component } from 'react';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import { MenuBar } from '../componentes/MenuBar';
import { BreadCrumb } from 'primereact/breadcrumb';
import { ProcessamentoFila } from './Processamento/ProcessamentoFila.js';
import usuarioService from '../services/usuarioService.js';

export class Processamento extends Component {
  constructor() {
    super();
    this.state = {
      lista: []
      , home: { icon: 'pi pi-home' }
      , agendamentos: []
      , activeItem: 0
      , tipoEvento: "tabela"
      , subMenu: [{ label: 'Novo', icon: 'pi pi-fw pi-plus' }, { label: 'Editar', icon: 'pi pi-fw pi-pencil' }, { label: 'Apagar', icon: 'pi pi-fw pi-trash' }]
      , usuario: {}
      , qtdeMensagens: ''
    };
  }
  componentDidMount() {
    this.consultaUsuario();
  }
  componentWillMount() {
    let aba = parseInt(this.props.match.params.id);
    this.setState({ activeItem: aba });
  }
  consultaUsuario() {
    usuarioService.consultaUsuario((usuario) => {
      this.setState({ usuario });
    });
  }

  render() {

    const items = [
      { label: 'Home', icon: 'pi pi-home', url: '/home' },
      { label: 'Processamento', icon: 'pi pi-home', url: '/home' },
      { label: 'Fila', icon: 'pi pi-home', url: '/processamento/fila' }
    ];
    return (

      <div>
        <MenuBar usuario={this.state.usuario} />
        <div className="content" >
          <div className="content align-center">
            <BreadCrumb home="principal" model={items} />
            <div className="pure-u-5-5">
                <ProcessamentoFila />
            </div>
          </div>
        </div>
        <div className="footer l-box">
        </div>
      </div>
    );
  }
}
