import { Button } from 'primereact/button';
import { Calendar } from 'primereact/calendar';
import { Card } from 'primereact/card';
import { Dialog } from 'primereact/dialog';
import { Dropdown } from 'primereact/dropdown';
import { SelectButton } from 'primereact/selectbutton';
import React, { Component, useEffect } from 'react';
import { useState } from 'react';
import $ from 'jquery';
import { ProgressBar } from 'primereact/progressbar';

const DialogGeracaoEventos = (props) => {

    const [indApuracao, setindApuracao] = useState(null);
    const [anoMesPerApur, setanoMesPerApur] = useState(null);
    const [isPeriodoGerado, setIsPeriodoGerado] = useState(false);
    const [isEventosPeriodicosGerados, setIsEventosPeriodicosGerados] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [idFolha, setIdFolha] = useState(null);
    const [mensagemRetorno, setMensagemRetorno] = useState({});
    const [mensagemRetornoGeracaoEvtsPer, setMensagemRetornoGeracaoEvtsPer] = useState([]);
    const [isBtnGeracaoDisabled, setIsBtnGeracaoDisabled] = useState(true);


    useEffect(()=>{
        console.log()
        if(anoMesPerApur!=null && indApuracao!=null){
            setIsBtnGeracaoDisabled(false);
        }
    }, [anoMesPerApur, indApuracao])


    function novaFolhaPagamento() {
        setIsLoading(true);
        var dados = {
            mes: (anoMesPerApur.getMonth() + 1).toString(),
            indApuracao: indApuracao,
            ano: anoMesPerApur.getFullYear()
        };

        $.ajax({
            url: "/EsocialAPI/folha/novo/",
            data: JSON.stringify(dados),
            method: 'post',
            contentType: "application/json",
            success: function (retorno) {
                console.log(retorno);
                setIsLoading(false);
                setIsPeriodoGerado(true);
                setIdFolha(retorno.idFolha)
                setMensagemRetorno(retorno.mensagem)
            }.bind(this),
            beforeSend: function (xhr, settings) { xhr.setRequestHeader('Authorization', 'Bearer ' + localStorage.getItem('auth-token')); }
        });
    }

    function gerarEventosPeriodicos(){
        setIsLoading(true);
        $.ajax({
            url: "/EsocialAPI/folha/gerarFolha/"+idFolha,
            method: 'post',
            contentType: "application/json",
            success: function (retorno) {
                console.log(retorno);
                setMensagemRetornoGeracaoEvtsPer(retorno)
                setIsLoading(false);
                setIsPeriodoGerado(false);
                setIsEventosPeriodicosGerados(true);
            }.bind(this),
            beforeSend: function (xhr, settings) { xhr.setRequestHeader('Authorization', 'Bearer ' + localStorage.getItem('auth-token')); }
        });
    }
    function renderFooter() {
        return (
            <span>
                <Button label="Detalhes" icon="pi pi-clone" style={{ margin: '10px' }} onClick={() => direcionar()} />
            </span>
        )
    }

    function direcionar() {
        var url = window.location.origin + "/folha/"+idFolha;
        window.location.replace(url);
    }

    function renderGerarEventosFolha() {
        return (
            <div className="fadeInDown">
                <Card title={mensagemRetorno.summary} subTitle="" footer={renderFooter()}>
                    <div>
                        <h4>Período: {anoMesPerApur.getMonth() + 1+'/'+anoMesPerApur.getFullYear()}</h4>
                        <h4>Indicativo de período de apuração: {indApuracao == 1 ? '1 - Mensal' : '2 - Anual (13° salário)'}</h4>
                    </div>
                    <div>
                        <p>
                            {mensagemRetorno.detail}
                        </p>
                    </div>
                </Card>
            </div>
        )
    }

    function renderRetornoGeracaoEventosPeriodicos() {
        return (
            <div className="fadeInDown">
                <Card title={mensagemRetorno.summary} subTitle="" footer={mensagemRetorno.severity == 'error' || isEventosPeriodicosGerados || isLoading ? '' : renderFooter()}>
                    <div>
                        <h4>Período: {anoMesPerApur.getMonth() + 1+'/'+anoMesPerApur.getFullYear()}</h4>
                        <h4>Indicativo de período de apuração: {indApuracao == 1 ? '1 - Mensal' : '2 - Anual (13° salário)'}</h4>
                    </div>
                    <div>
                        <p>
                            {mensagemRetornoGeracaoEvtsPer.map((str)=>{
                                return <p>{str.summary} - {str.detail}</p>
                            })}
                        </p>
                    </div>
                </Card>
            </div>
        )
    }

    function renderFormsGeracaoReferenciaFolha() {
        return (
            <Card className="p-fluid p-grid">

                <div className="p-field p-col-12 p-md-12">
                    <label htmlFor="anomesapur">Período de Apuração</label>
                    <Calendar id="anomesapur" value={anoMesPerApur} onChange={(e) => setanoMesPerApur(e.value)} view="month" dateFormat="mm/yy" yearNavigator yearRange="2010:2030" />

                </div>

                <div className="p-field p-col-12 p-md-12">
                    <label htmlFor="perapur">Indicativo de Apuração</label>
                    <Dropdown id="perapur" value={indApuracao} options={options} onChange={(e) => setindApuracao(e.value)} optionLabel="name" />
                </div>


            </Card>
        )
    }

    function renderContentDialog(isPeriodoGerado, isEventosPeriodicosGerados){
        if(isPeriodoGerado){
            return renderGerarEventosFolha();
        }else if(isEventosPeriodicosGerados){
            return renderRetornoGeracaoEventosPeriodicos();
        }else{
            return renderFormsGeracaoReferenciaFolha();
        }
    }

    const options = [
        { name: 'Mensal', value: 1 },
        { name: 'Anual (13° salário)', value: 2 },
    ]
    const footer = (
        <div>
            <Button label="Próximo" disabled={isBtnGeracaoDisabled} rightIcon="pi pi-step-backward-alt" onClick={() => novaFolhaPagamento()} />
        </div>
    );
    return (
        <div>
            <Dialog header="Geração de Folha de Pagamento" style={{ width: '40%' }} footer={isPeriodoGerado || isEventosPeriodicosGerados ? ' ': footer} visible={props.visible} modal={true} onHide={() => props.onHide()}>
                {isLoading ? <ProgressBar mode="indeterminate" /> : ''}
                {renderContentDialog(isPeriodoGerado, isEventosPeriodicosGerados)}
                

            </Dialog>
        </div>
    )

}

export default DialogGeracaoEventos;