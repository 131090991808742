import React, { useState, useEffect, useRef } from 'react';
import { MenuBar } from '../../../componentes/MenuBar';
import { Panel } from 'primereact/panel';
import { BreadCrumb } from 'primereact/breadcrumb';
import { SlideMenu } from 'primereact/slidemenu';
import $ from 'jquery';
import { Ajuda } from '../../Processamento/preview/Ajuda';
import { Button } from 'primereact/button';
import { FileUpload } from 'primereact/fileupload';
import usuarioService from '../../../services/usuarioService';
import tipoEventoService from '../../../services/tipoEventoService';

const EventoAjuda = () => {

    const [eventos, setEventos] = useState([]);
    const [usuario, setUsuario] = useState({});
    const [idEventoSelecionado, setIdEventoSelecionado] = useState();
    const [showAjuda, setShowAjuda] = useState(false);
    const [menuAtual, setMenuAtual] = useState([{label: 'Tipos'}]);

    useEffect(() => {
        console.log('Atualizando');
        consultaUsuario();
        consultaListaEventos();
    }, []);

    function consultaListaEventos() {
        tipoEventoService.listar(setEventos);
    }

    function consultaUsuario() {
        usuarioService.consultaUsuario(setUsuario);
    }

    function montaListEventosMenu(eventos) {
        console.log(eventos);
        const tiposMenu = eventos.map((obj) => {
            const listaEventos = obj.eventos;
            const listaEventosMenu = listaEventos.map((evt) => {
                return { label: evt.codigoEvento + ' - ' + evt.evento, icon: 'pi pi-file', command: () => {handleEventSlideMenuClick(evt, true) } }
            });
            return { label: obj.nomeTipo, items: listaEventosMenu, command:()=>{atualizaBreadCrumb(obj.nomeTipo)} }
        });
        console.log(tiposMenu);
        return tiposMenu;
    }

    function handleEventSlideMenuClick(evt, show){
        atualizaBreadCrumb(evt.codigoEvento + ' - ' + evt.evento);
        setIdEventoSelecionado(evt.idEvento); 
        setShowAjuda(show);

    }
    function atualizaBreadCrumb(label){
        console.log(label);
        let menu = menuAtual;
        menu.push({label:label});
        console.log(menu);
        setMenuAtual(menu);
    }

    function montaMenuAjuda(idEventoSelecionado, showAjuda) {
        if (showAjuda) {
            return <Ajuda idEventoEsocial={idEventoSelecionado}></Ajuda>
        } else {
            return (
                <div id="menu" >
                    <SlideMenu
                        model={montaListEventosMenu(eventos)}
                        style={{ width: 350 }}
                        viewportHeight={500}
                        menuWidth={350}
                        onShow={(e) => { console.log(e) }}
                        onHide={(e) => { console.log(e) }}
                    />
                </div>
            )
        }
    }

    function uploadArquivo(event) {
		
		var formData = new FormData();
		formData.append('file', event.files[0]);
		formData.append('codeventomensageria', 1010);
		$.ajax({
			method: 'POST',
			url: "/EsocialAPI/ajuda/leiaute/upload/",
			data: formData,
			processData: false,
			contentType: false,
			success: function (rsp) {
			}.bind(this),
			beforeSend: function (xhr, settings) { xhr.setRequestHeader('Authorization', 'Bearer ' + localStorage.getItem('auth-token')); }
		});
	}
    const items = [
        { label: 'Home', icon: 'pi pi-home', url: '/home' },
        { label: 'Administração', icon: 'pi pi-home', url: '/home' },
        { label: 'Ajuda', icon: 'pi pi-home', url: '/home' },
        { label: 'Ajuda Evento', icon: 'pi pi-home', url: '/EsocialReact/administracao/ajudaevento' }
    ];

    return (
        <div>
            <MenuBar usuario={usuario} />
            <BreadCrumb home="principal" model={items} />
            <div className="content">
                <div className="content align-center">
                    <Panel header="Ajuda de Eventos" >
                        <BreadCrumb home="principal" model={menuAtual} />
                        <br />
                        <div style={{ display: 'flex' }}>
                            {montaMenuAjuda(idEventoSelecionado, showAjuda)}
                        </div>
                        <Button icon="pi pi-cloud-upload" style={{margin:'10px'}} className="p-button-rounded p-button-success" />
                        <FileUpload
							name="file"
							mode="basic"
							url="./upload"
							auto={true}
							customUpload={true}
							uploadHandler={uploadArquivo}
							chooseLabel="Importar"
							style={{ 'display': 'inlineBlock' }}
						>
						</FileUpload>
                    </Panel>
                </div>
            </div>

        </div>
    );
}

export default EventoAjuda;