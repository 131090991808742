import React,{Component} from 'react';
import 'primereact/resources/themes/saga-green/theme.css';
import { Principal} from './sessoes/Principal';
import { MenuBar } from './componentes/MenuBar';
import {PermissionCheck} from './componentes/PermissionCheck';
import { locale, addLocale} from 'primereact/api';
import pt from './pt';
import usuarioService from './services/usuarioService';

export class Home extends Component {
  constructor(){
    super();
    this.state={visible: false,lista : [],menuBar :[],usuario:{}};
    this.changeLocale = this.changeLocale.bind(this);
  }
  componentDidMount(){
    usuarioService.consultaUsuario((usuario) => {
      this.setState({ usuario });
    });
  }

  changeLocale(){
    console.log(pt)
    addLocale('pt', pt.pt);
    locale('pt');
  }

  render() {
    this.changeLocale();
    return(
    <div>
      <MenuBar usuario={this.state.usuario} />
      <div className="content">
          <Principal  />

          </div>
          <div className="footer l-box is-center">
            <PermissionCheck usuario={this.state.usuario} url="/home" />
          </div>

    </div>
    );
  }
}
