import { Button } from 'primereact/button';
import React, { useState, useEffect } from 'react';


const CheckEventoProntoNovo = (props) => {

    const [isEventoPronto, setIsEventoPronto] = useState();

    useEffect(() => {

        props.eventoFila.isReady ? setIsEventoPronto(true) : setIsEventoPronto(false);
        
    }, [props.eventoFila])

    function renderCheckEvento(isEventoPronto) {
        if (isEventoPronto) {
            return <Button icon="pi pi-check" className="p-button-rounded p-button-success" tooltip="Evento Pronto para envio" tooltipOptions={{ position: 'top' }} />
        } else {
            return <Button icon="pi pi-times" className="p-button-rounded p-button-danger" tooltip="Evento Inconsistente" tooltipOptions={{ position: 'top' }} />
        }
        
    }

    return (
        <div>
            {renderCheckEvento(isEventoPronto)}
        </div>
    )
}

export default CheckEventoProntoNovo;
