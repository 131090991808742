import $ from 'jquery';
import Utils from '../utils/Util';

const tipoServidorService = {

    carregarDropdownTipoServidor(callback) {
        $.ajax({
            type: "GET",
            url: "/EsocialAPI/tiposervidor/lista",
            contentType: "application/json; charset=utf-8",
            error: function (e) {
                console.log(e);
            },
            success: function (resposta) {
                callback(resposta);
            }.bind(this),
            beforeSend: function (xhr, settings) { xhr.setRequestHeader('Authorization', 'Bearer ' + localStorage.getItem('auth-token')); },
            error: Utils.handleAjaxError
        });
    }

}

export default tipoServidorService;
