import Utils from '../utils/Util';
import $ from 'jquery';

const processamentoService = {
    filtrarProcessamentoFila(dados, callback, errorCallback) {
        fetch("/EsocialAPI/api/processamento/fila/", {
            method: "POST",
            headers: {
                "Content-Type": "application/json; charset=UTF-8",
                "Authorization": "Bearer " + localStorage.getItem("auth-token")
            },
            body: JSON.stringify(dados),
            timeout: 600000
        })
        .then(response => {
            if (!response.ok) {
                throw new Error("Erro na requisição.");
            }
            return response.json();
        })
        .then(resposta => {
            if (callback) callback(resposta);
        })
        .catch(error => {
            if (errorCallback) errorCallback(error);
            Utils.handleAjaxError(error);
        });
    },

    enviarEvento(idEventoFilas, callback) {
        $.ajax({
            type: "POST",
            data: { 'idEventoFila[]': idEventoFilas },
            url: "/EsocialAPI/api/processamento/enviar/",
            dataType: 'text',
            success: function (resposta) {
                callback(resposta)
            },
            beforeSend: function (xhr, settings) { xhr.setRequestHeader('Authorization', 'Bearer ' + localStorage.getItem('auth-token')); },
            error: Utils.handleAjaxError
        });
    }, 

    mudarAcaoEventos(idEventoFilas, dropdownAcaoModal, callback){
        $.ajax({
            type: "POST",
            data: { 'idEventoFila[]': idEventoFilas, 'acao': dropdownAcaoModal },
            url: "/EsocialAPI/api/processamento/mudar-acao/",
            dataType: 'text',
            success: function (resposta) {
                callback(resposta)
            },
            beforeSend: function (xhr, settings) { xhr.setRequestHeader('Authorization', 'Bearer ' + localStorage.getItem('auth-token')); },
            error: Utils.handleAjaxError
        });
    },

    validarEventos(evtsLote, callback){
        $.ajax({
            type: "POST",
            data: JSON.stringify(evtsLote),
            url: "/EsocialAPI/api/processamento/validar/",
            dataType: 'json',
            contentType: "application/json; charset=utf-8",
            success: function (resposta) {
                callback(resposta)
            }.bind(this),
            beforeSend: function (xhr, settings) { xhr.setRequestHeader('Authorization', 'Bearer ' + localStorage.getItem('auth-token')); },
            error: Utils.handleAjaxError
        });
    }
    
};

export default processamentoService;