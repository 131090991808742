import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { Panel } from 'primereact/panel';
import React, { useState } from 'react';
import { InputMask } from 'primereact/inputmask';

const FiltroLote = (props) => {

  const [isCollapsed, setIsCollapsed] = useState(true)

  const [dataEnvioInicial, setDataEnvioInicial] = useState(null)
  const [dataEnvioFinal, setDataEnvioFinal] = useState(null)
  const status = [{ label: 'Rejeitado', value: 'REJEITADO' }, { label: 'Pendente', value: 'PENDENTE' }, { label: 'Aguardando retorno', value: 'AGUARDANDO_RETORNO' }, { label: 'Em processamento', value: 'EM_PROCESSAMENTO' }, { label: 'Recebido', value: 'RECEBIDO' }, { label: 'Todos', value: '' }];

  function handleStatusChange(e) {
    props.onStatusChange(e.value)
  }

  function handleIdLoteChange(e) {
    const trimmedValue = e.target.value.trim();
    props.onIdLoteChange(trimmedValue);
  }

  function handleUsuarioChange(e) {
    props.onUsuarioChange(e.target.value)
  }

  function handleProtocoloChange(e) {
    const trimmedValue = e.target.value.trim();
    props.onProtocoloChange(trimmedValue);
  }

  function handleDtEnvioInicialChange(event) {
    const value = event.value; // Extract the updated value from the event object
    setDataEnvioInicial(value)
    props.onDtEnvioInicialChange(dataEnvioInicial); // Call the parent's prop function with the updated value
  }

  function handleDtEnvioFinalChange(event) {
    const value = event.value;
    setDataEnvioFinal(value)
    props.onDtEnvioFinalChange(dataEnvioFinal)
  }

  function renderFiltro() {
    return (
      <div style={{ margin: '15px' }}>
        <div className="p-grid" style={style.fieldContainer}>
          <div className="p-col-4" style={style.fieldGroup}>
            <label style={style.label}>ID Lote: </label>
            <InputText
              id="in"
              value={props.idLote}
              onChange={(e) => handleIdLoteChange(e)}
              style={style.inputText}
            />
          </div>
          <div className="p-col-4" style={style.fieldGroup}>
            <label style={style.label}>Protocolo: </label>
            <InputText
              id="in"
              value={props.protocolo}
              onChange={(e) => handleProtocoloChange(e)}
              style={style.inputText}
            />
          </div>
          <div className="p-col-4" style={style.fieldGroup}>
            <label style={style.label}>Status: </label>
            <Dropdown
              value={props.statusSelecionado}
              options={status}
              onChange={(e) => handleStatusChange(e)}
              placeholder="Escolha o status"
              style={style.dropDown}
            />
          </div>
        </div>
        <div className="p-grid" style={style.fieldContainer}>
          <div className="p-col-2" style={style.fieldGroup}>
            <label style={style.label}>Dt. Envio Inicial: </label>
            <InputMask
              id="date"
              mask="99/99/9999"
              value={dataEnvioInicial}
              placeholder="dd/mm/aaaa"
              slotChar="dd/mm/yyyy"
              onChange={(e) => handleDtEnvioInicialChange(e)}
              style={style.inputMask}
            />
          </div>
          <div className="p-col-2" style={style.fieldGroup}>
            <label style={style.label}>Dt. Envio Final: </label>
            <InputMask
              id="date"
              mask="99/99/9999"
              value={dataEnvioFinal}
              placeholder="dd/mm/aaaa"
              slotChar="dd/mm/yyyy"
              onChange={(e) => handleDtEnvioFinalChange(e)}
              style={style.inputMask}
            />
          </div>
        </div>
        <div className="p-grid" style={style.fieldContainer}>
        </div>
      </div>
    );
  }


  return (
    <div>
      <Panel header="Filtros" toggleable={true} collapsed={isCollapsed} onToggle={(e) => setIsCollapsed(e.value)}>
        {renderFiltro()}
        <div className="pure-u-3-3" style={{ textAlign: 'right' }}>
          <Button icon="pi pi-refresh" style={{ margin: '4px' }} onClick={() => props.onSubmit(false)} tooltip="Filtrar" tooltipOptions={{ position: 'right' }} label="Filtrar" />
        </div>
      </Panel>
    </div>
  )
};

const style = {
  label: {
    fontSize: 16,
    fontWeight: 'bold',
    display: 'block',
  },
  dropDown: {
    width: '100%', 
  },
  inputText: {
    width: '100%', 
  },
  inputMask: {
    width: '100%', 
  },
  inputContainer: {
    paddingTop: '5px', 
    paddingBottom: '5px', 
  },
  fieldContainer: {
    //display: 'flex', 
    marginBottom: '10px', // Increased the margin between rows
  },
  fieldGroup: {
    //flex: '1', 
    //marginRight: '10px', 
  },
};

export default FiltroLote;