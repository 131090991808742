import React, {Component} from 'react';
import $ from 'jquery';
import {Button} from 'primereact/button';
import {ProgressBar} from 'primereact/progressbar';
import {Messages} from 'primereact/messages';



export class ImportacaoEvento extends Component{
  constructor(){
    super();

    this.state={
      idEvento:0,
      aimportar:0,
      mesAnoApuracao:null,
      anoMesApur:null,
      url:'',
      data:null,
      method:'',
      tipoEvento:'',
      hash:'',
      totalRegImpor:0,
      statImportacao:null,
      idFolha:null
    }
    this.initialState = this.state;
    this.importarEventos = this.importarEventos.bind(this);
    this.carregaEventos = this.carregaEventos.bind(this);
    this.consultaImportacao = this.consultaImportacao.bind(this);
    this.handleClickConsultarImportacao = this.handleClickConsultarImportacao.bind(this);
  }
  componentDidMount(){
    console.log(this.props);
    this.setState({idEvento: this.props.idEvento});
    if(this.props.idGrupo===1){
      this.setState({url:'/EsocialAPI/api/importacao/tabela/'});
      this.setState({method:'POST'});
      this.setState({tipoEvento:'tabela'});
    }
    if(this.props.idGrupo===2){
      this.setState({url: '/EsocialAPI/api/importacao/nperiodico/'});
      this.setState({idEvento : this.props.idEvento, mesAnoApuracao : this.state.anoMesApur });
      this.setState({method:'POST'});
      this.setState({tipoEvento:'nperiodico'});
    }
    if(this.props.idGrupo===3){
      this.setState({url: '/EsocialAPI/api/importacao/periodico/'});
      this.setState({idEvento : this.props.idEvento, idFolha: this.props.idFolha });
      this.setState({idFolha: this.props.idFolha });
      this.setState({method:'POST'});
      this.setState({tipoEvento:'periodico'});
    }
  }

  carregaEventos(){
    console.log("/EsocialAPI/api/importacao/"+this.state.tipoEvento+"/preview");
    $.ajax({
      url:"/EsocialAPI/api/importacao/"+this.state.tipoEvento+"/preview",
      method:'POST',
      data: JSON.stringify ({idEvento : this.state.idEvento, idFolha: this.state.idFolha }),
      contentType: "application/json",
      dataType: 'json',
      success:function(resposta){
        this.setState({hash:resposta.hash},() => this.importarEventos());
        this.setState({ totalRegImpor: resposta.registros }, () => {
          setTimeout(() => {
            this.consultaImportacao();
          }, 3000);
        });
        
      }.bind(this),
      beforeSend: function(xhr, settings) { xhr.setRequestHeader('Authorization','Bearer ' + localStorage.getItem('auth-token')); }
    });
  }
  
  importarEventos(){
    $.ajax({
      url:this.state.url,
      method:this.state.method,
      data: JSON.stringify ({idEvento: this.state.idEvento, idFolha: this.state.idFolha, tokenImportacao: this.state.hash}),
      contentType: "application/json",
      dataType: 'json',
      timeout: 600000,
      success:function(resposta){
        console.log(resposta);
        this.validaRetorno(resposta);
        this.setState({aimportar:resposta.totalEventosAImportar});
        this.setState({hash: '', percentual: 0})
      }.bind(this),
      error:function(resposta){
        this.messages.replace({severity: 'error', summary: 'Não foi possível importar eventos! ', detail: 'Erro interno ao importar. Contate o suporte.', sticky: false, life:10000});
      },
      beforeSend: function(xhr, settings) { xhr.setRequestHeader('Authorization','Bearer ' + localStorage.getItem('auth-token')); }
    });
  }
  
  consultaImportacao(){
    $.ajax({
      url:"/EsocialAPI/api/importacao/consulta/",
      method:'POST',
      data: JSON.stringify ({idEvento: this.state.idEvento}),
      contentType: "application/json",
      dataType: 'json',
      success:function(resposta){
        this.messages.show(resposta);
      }.bind(this),
      beforeSend: function(xhr, settings) { xhr.setRequestHeader('Authorization','Bearer ' + localStorage.getItem('auth-token')); }
    });
  }
  
  handleClick(){
    $('#progressBar').show();
    this.messages.show({severity: 'info', summary: '', detail: 'Processando operação. Por favor, aguarde.', sticky:true});
    this.carregaEventos();
  }

  handleClickConsultarImportacao(){
    this.consultaImportacao();
  }

  validaRetorno(retorno){
    console.log(retorno);
    if(retorno.totalEventosAImportar>0){
      if(retorno.totalEventosAImportar!==retorno.eventosExistentes){
        $('#progressBar').hide();
        if(retorno.mensagemDTO!=null && retorno.mensagemDTO!=undefined){
          this.messages.replace(retorno.mensagemDTO);  
        }else{
          this.messages.replace({severity: 'success', summary: 'Eventos importados com sucesso! ', detail: retorno.eventosImportados+' eventos foram gerados a partir do Mentorh! Por favor, valide os eventos gerados.', stick: false, life:10000});  
        }
        
      }else{
        $('#progressBar').hide();
        if(retorno.mensagemDTO!=null && retorno.mensagemDTO!=undefined){
          this.messages.replace(retorno.mensagemDTO); 
        }else{
          this.messages.replace({severity: 'info', summary: '', detail: 'Não há eventos para importar. Todos os eventos já foram importados para fila.', sticky: false, life:10000});
        }
        
      }
    }else{
      $('#progressBar').hide();
      if(retorno.mensagemDTO!=null && retorno.mensagemDTO!=undefined){
        this.messages.replace(retorno.mensagemDTO); 
      }else{
        this.messages.replace({severity: 'error', summary: '', detail: 'Não foi possível importar eventos! Evento inexistente na base ou não foi implementado.', sticky: false, life:10000});
      }
      
    }
  }

  render(){
    return(
        <div>
            
            <h5>Clique para Gerar os Eventos</h5>
    
            <div id={this.props.idEvento+'_pgbar'} style={{marginBottom:'30px'}}>
                <Messages id="msgInfo" ref={(el) => this.messages = el}></Messages>
            </div>
            <Button label="Gerar Evento" icon="pi pi-check" iconPos="left" id="btnImportar" onClick={this.handleClick.bind(this)}/>
            <Button style={{marginLeft: 5}} label="Consultar Andamento" className="p-button-info" icon="pi pi-info-circle" iconPos="left" id="btnConsultar" onClick={this.handleClickConsultarImportacao}/>
            <br />
            
        </div>
    );
  }
}