import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Dialog } from 'primereact/dialog';
import React, { useState } from 'react';
import XMLViewer from 'react-xml-viewer';
import { EventoFila } from '../../EventoFila';
import { Badge } from 'primereact/badge';


const EventoFilaLote = props => {

  const [visiblePreview, setVisiblePreview] = useState(false);
  const [xml, setXml] = useState("");
  const [idEventoFilaSelecionado, setIdEventoFilaSelecionado] = useState("");
  const [isDetalheVisible, setIsDetalheVisible] = useState(false);

  function handleClickXmlEnviado(rowData){
    setXml(rowData.xmlSaida);
    setVisiblePreview(true);
  }

  function handleClickXmlRecebido(rowData){
    setXml(rowData.xmlEntrada);
    setVisiblePreview(true);
  }

  function actionTemplate(rowData) {
    return (
      <div style={{ display: 'inline-block' }}>
        <Button style={{margin:'5px'}} icon="pi pi-file-o" className="p-button-sm p-button-rounded p-button-success" onClick={()=>handleClickXmlEnviado(rowData)} tooltip="XML Enviado"/>
        <Button style={{margin:'5px'}} icon="pi pi-file-o" className="p-button-sm p-button-rounded p-button-warning" onClick={()=>handleClickXmlRecebido(rowData)} tooltip="XML retornado pelo eSocial"/>
        <Button style={{margin:'5px'}} icon="pi pi-eye" className="p-button-sm p-button-rounded p-button-info" onClick={(e) => abrirModalEventoFila(rowData.idEventoFila, true)} tooltip="Página do Evento"/>
      </div>
    )
  }

  function renderTipoEvento(rowData) {
    return <Badge value={rowData.codEvento} className='p-badge-warning-outlined'/>;
  }

  function abrirModalEventoFila(ideEventoFila,isVisible) {
    setIdEventoFilaSelecionado(ideEventoFila)
    setIsDetalheVisible(isVisible)
  }

  function renderStatusEvento(rowData) {
    let status = 'info';
  
    switch (rowData.statusEvento) {
      case 'RECEBIDO':
        status = 'success';
        break;
      case 'REJEITADO':
        status = 'danger';
        break;
      case 'PENDENTE':
        status = 'warning';
        break;
      default:
        break;
    }
  
    return (
        <div>
            <Badge value={rowData.statusEvento} severity={status} className='p-badge-square'/>
        </div>
      
    );
  }

  return (
    <div style={{ height: "400px", textAlign: "left" }}>
      <Dialog header="Visualização evento XML" visible={visiblePreview} style={{ width: '50vw' }} modal onHide={() => setVisiblePreview(false)}>
          <XMLViewer xml={xml} />
      </Dialog>
      <Dialog
          header="Detalhe Evento"
          visible={isDetalheVisible}
          modal={true}
          onHide={() => setIsDetalheVisible(false)}
          maximizable
          style={{ textAlign: 'center', width:'85%' }}>
        <EventoFila isMenuDisabled = {true} match = {{params: {id: idEventoFilaSelecionado}}}/>
      </Dialog>
      <DataTable value={props.eventosLote} emptyMessage="O lote não possui eventos">
          <Column field="idEventoFila" header="ID" style={{ width: '4%', textAlign: 'center' }} />
          <Column body={renderTipoEvento} header="Evento" style={{ width: '4%', textAlign: 'center' }} />
          <Column field="descEventoFila" header="Detalhe" style={{ width: '4%', textAlign: 'center' }} />
          <Column body={renderStatusEvento} header="Status" style={{ width: '4%', textAlign: 'center' }} />
          <Column header="Ações" body={actionTemplate} style={{ textAlign: 'center', width: '5%' }} />
      </DataTable>
    </div>
  );
};


export default EventoFilaLote;
