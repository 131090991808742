import $ from 'jquery';
import Utils from '../utils/Util';

const filtroPersonalizadoService = {

    consultaFiltroPersonalizado(callback) {
        $.ajax({
            type: "GET",
            url: "/EsocialAPI/filtropersonalizado/lista",
            contentType: "application/json; charset=utf-8",
            success: function (resposta) {
                callback(resposta);
            }.bind(this),
            beforeSend: function (xhr, settings) { xhr.setRequestHeader('Authorization', 'Bearer ' + localStorage.getItem('auth-token')); },
            error: Utils.handleAjaxError
        });
    }

}

export default filtroPersonalizadoService