import React, { Component } from 'react';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import XMLViewer from 'react-xml-viewer';
import { TabView, TabPanel } from 'primereact/tabview';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { ProgressSpinner } from 'primereact/progressspinner';
import $ from 'jquery';
import { Panel } from 'primereact/panel';
import EventoFilaLote from '../preview/EventoFilaLote';

export class LoteView extends Component {
  constructor() {
    super();
    this.state = { 
      eventos: []
      , isPronto: false
      , visible: false
      , activeIndex: 0
      , xmlEnvio: null
      , xmlRetorno: null
      , dataEnvio: null
      , dataEnvioFormatada: null
    };
    this.consultaInfoLote = this.consultaInfoLote.bind(this);
    this.onClick = this.onClick.bind(this);
    this.onHide = this.onHide.bind(this);
  }

  consultaInfoLote() {
    let arrEventos = [];
    $.ajax({
      type: "GET",
      url: "/EsocialAPI/api/lote/xml/" + this.props.idLote,
      dataType: 'json',
      contentType: "application/json; charset=utf-8",
      success: function (resposta) {
        arrEventos = resposta.eventos;
        if (arrEventos !== null && arrEventos.length > 0) {
          this.setState({ eventos: arrEventos });
        }

        this.setState({ 
          xmlEnvio: resposta.xmlEnvio
          , xmlRetorno: resposta.xmlRetorno
          , dataEnvio: resposta.dataEnvio
          , dataEnvioFormatada: resposta.dataEnvioFormatada 
        });

        $("#LdlLoteView").fadeOut();
        $("#infoLote").fadeIn();
      }.bind(this),
      error: function (erro) {

      },
      beforeSend: function (xhr, settings) { xhr.setRequestHeader('Authorization', 'Bearer ' + localStorage.getItem('auth-token')); }
    });
  }

  onClick() {
    this.setState({ visible: true });
    this.consultaInfoLote();
  }

  onHide() {
    this.setState({ visible: false, activeIndex: 0 });
  }

  render() {
    const { useLink } = this.props;

    const renderButton = (
      <Button
        type="button"
        icon="pi pi-info-circle"
        className="p-button-info"
        style={{ marginRight: '.5em' }}
        onClick={this.onClick}
        tooltip="Detalhes do Lote"
        tooltipOptions={{ position: 'top' }}
      />
    );

    const renderLink = (
      <a href="#" onClick={this.onClick} className='link-custom'>{this.props.idLote}</a>
    );

    const renderButtonOrLink = useLink ? renderLink : renderButton;

    return (
      <div>
        <Dialog header="Descricão do Lote" visible={this.state.visible} style={{ width: '90vw' }} onHide={this.onHide}>

          <Panel header={"Lote: " + this.props.idLote + " - Enviado em: " + this.state.dataEnvioFormatada}>
            <div id="LdlLoteView" style={{ display: 'block' }}>
              <ProgressSpinner style={{ width: '50px', height: '50px' }} strokeWidth="8" fill="#EEEEEE" />
              <em>Carregando informações do lote.</em>
            </div>
            <div style={{ display: 'none' }} id="infoLote">
              <TabView activeIndex={this.state.activeIndex} onTabChange={(e) => this.setState({ activeIndex: e.index })} >
                <TabPanel header="Eventos do Lote" >
                  <div id="divValidado" style={{ overflowY: 'scroll', height: '450px' }}>
                    <EventoFilaLote eventosLote={this.state.eventos}/>
                  </div>
                </TabPanel>
                <TabPanel header="Xml do Lote Enviado" >
                  <div id="divValidado" style={{ overflowY: 'scroll', height: '450px' }}>
                    <XMLViewer xml={this.state.xmlEnvio} />
                  </div>
                </TabPanel>
                <TabPanel header="Xml de Retorno do Lote" >
                  <div id="divRetorno" style={{ overflowY: 'scroll', height: '450px' }}>
                    <XMLViewer xml={this.state.xmlRetorno} />
                  </div>
                </TabPanel>
              </TabView>
            </div>
          </Panel>
        </Dialog>
        {renderButtonOrLink}
      </div>
    );
  }
}
