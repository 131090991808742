import React, {Component} from 'react';
import $ from 'jquery';
import './../css/marketing.css';
import { Chart } from 'primereact/chart';
export class Grafico extends Component{
  constructor(){    
      super();
      this.state={lista : []
        ,resposta:[]
        ,agendamentos:[]
        ,dataSets:{}
        ,tipoEvento:"tabela"
        ,subMenu:[{label: 'Novo', icon: 'pi pi-fw pi-plus'},{label: 'Editar', icon: 'pi pi-fw pi-pencil'},{label: 'Apagar', icon: 'pi pi-fw pi-trash'}]
      };
  }
  componentWillMount(){
    console.log(this.props.idTipoEvento);
    $.ajax({
      url:"/EsocialAPI/api/dashboard/columns/"+this.props.idTipoEvento,
      dataType: 'json',
      success:function(resposta){
        console.log(resposta);
        this.setState({resposta:resposta});
      }.bind(this),
      beforeSend: function(xhr, settings) { xhr.setRequestHeader('Authorization','Bearer ' + localStorage.getItem('auth-token')); }
    });
  }


  render(){
    const stackedData = {
      labels: this.state.resposta.labels,
datasets: [
  {
    label: 'Pendente',
    backgroundColor: '#FFCA28',
    data: this.state.resposta.pendentes
}, {
  label: 'Enviado',
  backgroundColor: '#66BB6A',
  data: this.state.resposta.enviado
}, {
  label: 'Processado',
  backgroundColor: '#42cbf5',
  data: this.state.resposta.processado
},{
  label: 'Rejeitado',
  backgroundColor: 'red',
  data: this.state.resposta.rejeitados
}, {
  label: 'Cancelado',
  backgroundColor: 'black',
  data: this.state.resposta.cancelado
      }]
  };
  const stackedOptions = {
      tooltips: {
          mode: 'index',
          intersect: false
      },
      responsive: true,
      scales: {
          xAxes: [{
              stacked: true,
          }],
          yAxes: [{
              stacked: true
          }]
      }

     };
    return(
      <div>
        <Chart type="bar" data={stackedData} options={stackedOptions} width="80%" />       
      </div>
    );
  }
}