import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import * as serviceWorker from './serviceWorker';
import {BrowserRouter,Route,Switch, Redirect } from 'react-router-dom';
import {Processamento} from './sessoes/Processamento';
import {ProcessamentoLote} from './sessoes/ProcessamentoLote.js';
import {ProcessamentoAgendado} from './sessoes/ProcessamentoAgendado';
import {ProcessamentoAutomatizado} from './sessoes/Processamento/ProcessamentoAutomatizado';
import {Folha} from './sessoes/Folha';
import {Auditoria} from './sessoes/Administracao/Auditoria';
import {Configuracao} from './sessoes/Configuracao';
import {Qualificacao} from './sessoes/Qualificacao.js';
import {Trabalhador} from './sessoes/Trabalhador.js';
import {Certificado} from './sessoes/Certificado.js';
import {Importacao} from './sessoes/Importacao';
import {RelEventosInconsistentes} from './sessoes/Relatorios/RelEventosInconsistentes.js';
import {RelAfastamento} from './sessoes/Relatorios/RelAfastamento.js';
import {UserConfig} from './sessoes/UserConfig.js';
import {RelImportacao} from './sessoes/Relatorios/RelImportacao.js';
import {RelEventosTrabalhador} from './sessoes/Relatorios/RelEventosTrabalhador.js';
import {Usuario} from './sessoes/Usuario.js';
import {Ajuda} from './sessoes/Ajuda.js';
import {Grupo} from './sessoes/Grupo.js';
import {Home} from './Home.js';
import {Login} from './Login.js';
import {AjudaEvento} from './sessoes/Administracao/Ajuda/AjudaEvento';
import {AjudaSessao} from './sessoes/Administracao/Ajuda/AjudaSessao';
import {EventoFila} from './sessoes/EventoFila';
import {Catalogo} from './sessoes/Catalogo';
import {Evento} from './sessoes/Evento';
import {FolhaPagamento} from './sessoes/FolhaPagamento';
import {CargaInicial} from './sessoes/Administracao/CargaInicial/CargaInicial'
import {Mensagem} from './sessoes/Mensagem/Mensagem.js'
import EventoAjuda from './sessoes/Administracao/Ajuda/EventoAjuda';
import {Erro} from './sessoes/Erro.js';
import Agenda from './sessoes/Administracao/Agenda/Agenda';
import RelEventosRejeitados from './sessoes/Relatorios/RelEventosRejeitados';
import {LogViewer} from './sessoes/LogViewer';
import {TesteEnvio} from './sessoes/Administracao/TesteEnvio/TesteEnvio';
import { RelTotalizadoresNovo } from './sessoes/Relatorios/RelTotalizadoresNovo';
import RelEventosRecebidos from './sessoes/Relatorios/RelEventosRecebidos.js';
import { ExclusaoRecuperacao } from './sessoes/ExclusaoRecuperacao.js';

  const isAuthenticated = () => {
    const authToken = localStorage.getItem('auth-token');
    return !!authToken;
  };

  const ProtectedRoute = ({ component: Component, ...rest }) => (
    <Route
      {...rest}
      render={(props) =>
        isAuthenticated() ? (
          <Component {...props} />
        ) : (
          <Redirect to="/" />
        )
      }
    />
  );

ReactDOM.render(
  (<BrowserRouter basename={process.env.REACT_APP_ROUTER_BASE || ''}>
    <Switch>
        <Route path="/" component={Login} exact={true} />
        <ProtectedRoute path="/home" component={Home} />
        <ProtectedRoute path="/erro/:id" component={Erro} />
        <ProtectedRoute path="/qualificacao" component={Qualificacao} />
        <ProtectedRoute path="/processamento/fila/:id" component={Processamento}/>
        <ProtectedRoute path="/processamento/lote" component={ProcessamentoLote}/>
        <ProtectedRoute path="/processamento/agendamento" component={ProcessamentoAgendado}/>
        <ProtectedRoute path="/processamento/automatizado" component={ProcessamentoAutomatizado}/>
        <ProtectedRoute path="/exclusao" component={ExclusaoRecuperacao}/>
        <ProtectedRoute path="/EventoFila/:id" component={EventoFila}/>
        <ProtectedRoute path="/Catalogo/" component={Catalogo}/>
        <ProtectedRoute path="/Evento/:id" component={Evento}/>
        <ProtectedRoute path="/Sessao/:id" component={EventoFila}/>
        <ProtectedRoute path="/FolhaPagamento/" component={FolhaPagamento}/>
        <ProtectedRoute path="/folha/:id" component={Folha}/>
        <ProtectedRoute path="/configuracao" component={Configuracao}/>
        <ProtectedRoute path="/administracao/auditoria" component={Auditoria}/>
        <ProtectedRoute path="/usuario" component={Usuario}/>
        <ProtectedRoute path="/ajuda" component={Ajuda}/>
        <ProtectedRoute path="/administracao/ajudaeventoold" component={AjudaEvento}/>
        <ProtectedRoute path="/administracao/ajudaevento" component={EventoAjuda}/>
        <ProtectedRoute path="/administracao/ajudasessao" component={AjudaSessao}/>
        <ProtectedRoute path="/administracao/cargainicial" component={CargaInicial}/>
        <ProtectedRoute path="/administracao/agenda" component={Agenda}/>
        <ProtectedRoute path="/mensagem" component={Mensagem}/>
        <ProtectedRoute path="/grupos" component={Grupo}/>
        <ProtectedRoute path="/trabalhador" component={Trabalhador}/>
        <ProtectedRoute path="/certificado" component={Certificado}/>
        <ProtectedRoute path="/importacao" component={Importacao}/>
        <ProtectedRoute path="/relatorio/afastamento" component={RelAfastamento}/>
        <ProtectedRoute path="/user/config" component={UserConfig}/>
        <ProtectedRoute path="/relatorio/evEnviados" component={RelEventosRecebidos}/>
        <ProtectedRoute path="/relatorio/evTrab" component={RelEventosTrabalhador}/>
        <ProtectedRoute path="/relatorio/inconsistencias" component={RelEventosInconsistentes}/>
        <ProtectedRoute path="/relatorio/rejeitados" component={RelEventosRejeitados}/>
        <ProtectedRoute path="/relatorio/importacao" component={RelImportacao}/>
        <ProtectedRoute path="/relatorio/totalizadores" component={RelTotalizadoresNovo}/>
        <ProtectedRoute path="/log" component={LogViewer}/>
        <ProtectedRoute path="/teste-envio" component={TesteEnvio}/>
    </Switch>
  </BrowserRouter>), document.getElementById('root'));

serviceWorker.register();
//serviceWorker.unregister();
