import React, { Component, useEffect, useState } from 'react';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import $ from 'jquery';
import { Button } from "primereact/button";
import { Dialog } from 'primereact/dialog';
import { ProgressBar } from 'primereact/progressbar';
import { Toast } from 'primereact/toast';

const BotaoConsultaLote = (props) => {

    const [isVisible, setIsVisible] = useState(false)
    const [mensagem, setMensagem] = useState("")
    const [progressBarVisible, setProgressBarVisible] = useState(true)

    function onHide(){
        setIsVisible(false)
        props.atualizarGrid(false)
    }

    function showToast(arrMensagens) {
        //props.toast.show(arrMensagens);
    }

    function consultaServicoRetorno(protocolo) {

        setMensagem("Consultando o lote nos Web Services do eSocial, favor aguarde alguns instantes...")
        setIsVisible(true)
        console.log(protocolo);
        $.ajax({
            type: "POST",
            data: { "protocolo": protocolo },
            url: "/EsocialAPI/ConsultaRetorno/",
            dataType: 'text',
            success: function (resposta) {
                console.log(resposta);
                /*
                let arrMensagem = [];
                arrMensagem.push({severity:resposta.tipoMensagem, detail: resposta.mensagem});

                if(arrMensagem.length > 0) {
                    showToast(arrMensagem);
                }
                */
                setTimeout(onHide, 10000);
                setMensagem(resposta.mensagem)

            },
            error: function (resposta){
                console.log(resposta);
                /*
                let arrMensagem = [];
                arrMensagem.push({severity:resposta.tipoMensagem, detail: resposta.mensagem});

                if(arrMensagem.length > 0) {
                    showToast(arrMensagem);
                }
                */
                
                setProgressBarVisible(false)
                setMensagem(resposta.mensagem)
                setTimeout(onHide, 4000);
            }
            ,beforeSend: function(xhr, settings) { xhr.setRequestHeader('Authorization','Bearer ' + localStorage.getItem('auth-token')); }
        });
    }

    return (

        <div>

            <Button style={{ marginRight: '.5em' }} type="button" icon="pi pi-replay" onClick={(e) => consultaServicoRetorno(props.protocolo)} tooltip="Atualizar Lote" tooltipOptions={{position: 'top'}} disabled={props.disabled}></Button>
            
            <Dialog header="Lote Em Processamento." visible={isVisible} style={{ width: '50vw' }} onHide={() => onHide()} position={"top"} draggable={false} resizable={false} dismissableMask={false} closable={false}>
                <p>{mensagem}</p>
                {progressBarVisible?<ProgressBar mode="indeterminate" />:""}
            </Dialog>

        </div>
    )
}

export default BotaoConsultaLote;
